

















import PermissionResolver from '@/services/Permissions/PermissionResolver';
import {IEaasiUser} from 'eaasi-admin';
import Vue from 'vue';
import Component from 'vue-class-component';
import {Get} from 'vuex-pathify';
import LeftMenuItem from './LeftMenuItem.vue';
import { IMenuItem } from 'eaasi-nav';
import { ROUTES } from '@/router/routes.const';

@Component({
	components: {
		LeftMenuItem
	}
})
export default class LeftMenu extends Vue {
	@Get('loggedInUser')
	user: IEaasiUser;

	@Get('permissions')
	permit: PermissionResolver;

	userCanManageNode() {
		if (!this.user) return false;
		return this.permit.allowsViewManageNodePage();
	}

	get menuItems() : IMenuItem[] {
		let menu = [
			{
				icon: 'home',
				label: 'My Dashboard',
				route: ROUTES.DASHBOARD,
			},
			{
				icon: 'file-search',
				label: 'Explore Resources',
				route: ROUTES.RESOURCES.EXPLORE,
			},
			{
				icon: 'clipboard-list',
				label: 'My Resources',
				route: ROUTES.RESOURCES.MY_RESOURCES,
			},
			{
				icon: 'atom',
				label: 'Emulation Project',
				route: ROUTES.EMULATION_PROJECT.ROOT,
			},
			{
				icon: 'upload',
				label: 'Import Resource',
				route: ROUTES.IMPORT_RESOURCE,
			}];

		if (this.userCanManageNode()) {
			menu.push({
				icon: 'manage',
				label: 'Manage Node',
				route: ROUTES.MANAGE_NODE.ROOT,
			});
		}

		return menu;
	}

	navigateToDashboard() {
		this.$router.push(ROUTES.DASHBOARD);
	}

}

