














































































import EnvironmentImportResource from '@/models/import/EnvironmentImportResource';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ImportPathSelect from '../ImportPathSelect.vue';
import { ResourceImportPath } from '@/types/Import';
import SoftwareGeneralInfoForm from '@/components/import/software/SoftwareGeneralInfoForm.vue';
import SoftwareProductMetadataForm from '@/components/import/software/SoftwareProductMetadataForm.vue';
import SoftwareRequirementsForm from '@/components/import/software/SoftwareRequirementsForm.vue';
import SoftwareVersionForm from '@/components/import/software/SoftwareVersionForm.vue';
import EaasiForm from '@/components/global/forms/EaasiForm.vue';
import { Get, Sync } from 'vuex-pathify';

@Component({
	name: 'SoftwareMetadata',
	components: {
		ImportPathSelect,
		SoftwareGeneralInfoForm,
		SoftwareProductMetadataForm,
		SoftwareRequirementsForm,
		SoftwareVersionForm
	}
})
export default class SoftwareMetadata extends Vue {

	$refs!: {
		_form: EaasiForm
	};

	/* Computed
	============================================*/
	@Sync('import/importPath')
	importPath: ResourceImportPath;

	@Sync('import/importStep')
	step: number;

	@Get('import/software@title')
	readonly title: string;

	@Sync('import/software')
	software: EnvironmentImportResource;

	/* Data
	============================================*/

	versions: any[] = [{id: 1}]; // TODO

	/* Methods
	============================================*/

	addSoftwareVersion() {
		// TODO:
		let id = Math.max.apply(null, this.versions.map(x => x.id)) + 1;
		this.versions.push({id});
	}

	goToNextStep() {
		this.step++;
	}

	submitForm() {
		// Workaround vue/typescript bug where _form type is 'Vue' rather than 'EaasiForm'
		let form: any = this.$refs._form;
		form.submit();
	}
}

