













import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

/**
 * A container for SelectableCard content.
 * Presents data provided in a prop object in key-value format.
 */
@Component({
	name: 'SelectableCardContent',
})
export default class SelectableCardContent extends Vue {
    /* Props
    ============================================*/
    @Prop({type: Object, required: false})
    contentData: object
}
