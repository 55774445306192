





















































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
	name: 'EnvironmentImportOSMetadata',
})
export default class EnvironmentImportOSMetadata extends Vue { }

