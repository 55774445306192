













































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import AdminScreen from '../AdminScreen.vue';
import config from '@/config';
import EaasiIcon from '@/components/global/icons/EaasiIcon.vue';

@Component({
    name: 'TroubleshootingSection',
	components: {
		EaasiIcon
	}
})
export default class TroubleshootingSection extends AdminScreen {

    /* Props
    ============================================*/

    /* Computed
    ============================================*/
    get emilErrorDownloadUrl(): string {
        return config.EMIL_SERVICE_ENDPOINT + '/error-report';
	}
	
	get webApiErrorDownloadUrl(): string {
		return config.SERVICE_URL + '/error-report/download-all';
	}

    /* Data
    ============================================*/

    /* Methods
    ============================================*/

    /* Lifecycle Hooks
    ============================================*/

}
