






import { Component } from 'vue-property-decorator';
import Vue from 'vue';

/**
 * Provides Wizard to Add Software Resources
 */
@Component({
	name: 'SoftwareResourcesWizard'
})
export default class SoftwareResourcesWizard extends Vue {
}

