







































































import Vue from 'vue';
import {Component, Watch} from 'vue-property-decorator';
import { UiButton } from '@/components/global';
import InfoModal from '@/components/global/Modal/InfoModal.vue';
import SelectList from '@/components/global/forms/SelectList.vue';
import OsPicker from '../shared/OsPicker.vue';
import { IDrive, IUIOsItem } from '@/types/Resource';
import { Sync } from 'vuex-pathify';
import { operatingSystems, IOsListItem } from '@/models/admin/OperatingSystems';
import { populateNativeConfig } from '@/helpers/NativeConfigHelper';
import { ICreateEnvironmentPayload } from '@/types/Import';
import OsTemplateConfig from '../shared/OsTemplateConfig.vue';
import { defaultOsList } from '@/utils/constants';

@Component({
	name: 'CreateBaseEnvModal',
	components: {
		OsPicker,
		OsTemplateConfig,
		InfoModal,
		UiButton,
		SelectList,
	}
})
export default class CreateBaseEnvModal extends Vue {

	/* Computed
	============================================*/
	@Sync('emulationProject/createEnvironmentPayload@label')
	environmentTitle: string;

	@Sync('emulationProject/createEnvironmentPayload@driveSettings')
	drives: IDrive[];

	@Sync('emulationProject/createEnvironmentPayload@operatingSystemId')
	operatingSystemId: string;

	@Sync('emulationProject/createEnvironmentPayload@templateId')
	templateId: string;

	@Sync('emulationProject/createEnvironmentPayload@nativeConfig')
	nativeConfig: string;

	@Sync('emulationProject/createEnvironmentPayload')
	createEnvironmentPayload: ICreateEnvironmentPayload;

	get canSave(): boolean {
		return !!this.templateId && !!this.operatingSystemId && !!this.environmentTitle;
	}

	get osTemplates(): IOsListItem[] {
		if (!this.selectedOs) return this.operatingSystems;
		return this.operatingSystems.filter(os => os.id.indexOf(this.selectedOs.value) >= 0);
	}

	get selectedOSTemplate(): IOsListItem {
		return this.operatingSystems.find(os => os.id === this.operatingSystemId);
	}

	/* Data
	============================================*/
	readonly operatingSystems = operatingSystems;
	selectedOs: IUIOsItem = null;

	reset() {
		const currentEnvTitle = this.environmentTitle;
		this.$store.commit('emulationProject/RESET');
		this.environmentTitle = currentEnvTitle;
	}

	selectOs(os: IUIOsItem) {
		this.selectedOs = os;
	}

	selectOsForTemplate(template: string) {
		const templateVal = template.split(':')[1];
		const os: IUIOsItem = defaultOsList.find(os => os.value.indexOf(templateVal) >= 0);
		if (!os) return;
		this.selectedOs = os;
	}

	beforeDestroy() {
		this.environmentTitle = null;
		this.drives = [];
		this.operatingSystemId = null;
		this.templateId = null;
		this.nativeConfig = null;
	}

	@Watch('operatingSystemId')
	onActiveTemplate(template) {
		if (!template) this.reset();
		const chosenOS = this.operatingSystems.find(os => os.id === template);
		if (!chosenOS) return;
		this.selectOsForTemplate(template);
		this.nativeConfig = populateNativeConfig(chosenOS.template_params);
		this.templateId = chosenOS.template;
	}

}

