











import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

/**
 * A general chip for UI interaction
 * @example ../docs/UiChip.Example.md
 */
@Component({
	name: 'UiChip'
})
export default class UiChip extends Vue {

	/* Props
    ============================================*/

	/**
	 * Add close button
	 */
	@Prop({type: Boolean, required: false, default: false})
    readonly close: boolean;
}
