




















































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Get } from 'vuex-pathify';
import { IEaasiUser, IEaasiRole } from 'eaasi-admin';
import { IRadioOption } from '@/types/Forms';
import FormModal from '@/components/global/forms/FormModal.vue';
import DescriptiveRadios from '@/components/global/forms/DescriptiveRadios.vue';
import TextInput from '@/components/global/forms/TextInput.vue';
import Modal from '@/components/global/Modal/Modal.vue';
import { userRoles } from '@/utils/constants';
import config from '../../../config';
import { generateNotificationError, generateNotificationSuccess } from '@/helpers/NotificationHelper';
import eventBus from '../../../utils/event-bus';

@Component({
	name: 'UserModal',
	components: {
		FormModal,
		DescriptiveRadios,
		TextInput,
		Modal
	}
})
export default class UserModal extends Vue {
	/* Data
	============================================*/
	isDeleteModalVisible: boolean = false;
	isResetPasswordModalVisible: boolean = false;

	/* Props
	============================================*/

	@Prop({type: Object as () => IEaasiUser, required: true})
	readonly user: IEaasiUser;

	@Prop()
  readonly oldRoleId: number;

	/* Computed
	============================================*/

	@Get('admin/roles')
	readonly roles: IEaasiRole[];

	@Get('loggedInUser')
	readonly loggedInUser: IEaasiUser;

	get isNew() {
		return !this.user.id;
	}

	get modalTitle() {
		return this.isNew ? 'Create New User' : 'Edit User';
	}

	get showResetPassword(): boolean {
		return this.isAdmin && !config.SAML_ENABLED;
	}

	get isAdmin(): boolean {
		return this.loggedInUser?.roleId === userRoles.ADMIN;
	}

	get radioOptions(): IRadioOption[] {
		if (!this.roles) return [];
		return this.roles.map(x => {
			return {
				value: x.id,
				label: x.roleName,
				description: x.roleDescription
			};
		});
	}

	/* Methods
	============================================*/

	async handleSaveUser() {
		if (this.isNew) {
			return await this.saveUser();
		}
		return await this.saveExistingUser();
	}

	async saveExistingUser() {
		let result = await this.$store.dispatch('admin/saveExistingUser', {user: this.user, roleUpdated: this.oldRoleId !== this.user.roleId});
		if (result.hasError) {
			const notification = generateNotificationError(result.message);
			eventBus.$emit('notification:show', notification);
		} else {
			await this.$store.dispatch('admin/getUsers');
		}
		this.$emit('close');
	}

	async saveUser() {
		let result = await this.$store.dispatch('admin/saveUser', this.user);
		if (result.hasError) {
			const notification = generateNotificationError(result.message);
			eventBus.$emit('notification:show', notification);
		} else {
			const message = `Account for ${this.user.username} created. Provide this temporary password to the user: ${result}`;
			const notification = generateNotificationSuccess(message, 20000);
			eventBus.$emit('notification:show', notification);
			await this.$store.dispatch('admin/getUsers');
		}
		this.$emit('close');
	}

	showDeleteModal() {
		this.isDeleteModalVisible = true;
	}

	async confirmDeleteUser() {
		let result = await this.$store.dispatch('admin/deleteUser', this.user.id);
		if (result.hasError) {
			const notification = generateNotificationError(result.message);
			eventBus.$emit('notification:show', notification);
		} else {
			await this.$store.dispatch('admin/getUsers');
		}
		this.isDeleteModalVisible = false;
		this.$emit('close');
	}

	async resetPassword() {
		const result = await this.$store.dispatch('admin/resetPassword', {id: this.user.id, email: this.user.email});
		if (result.hasError) {
			const notification = generateNotificationError(result.message);
			eventBus.$emit('notification:show', notification);
		} else {
			const message = `You have successfully reset ${this.user.username}'s password. Provide this temporary password to the user: ${result}`;
			const notification = generateNotificationSuccess(message, 20000);

			eventBus.$emit('notification:show', notification);
		}
		this.isResetPasswordModalVisible = false;
		this.$emit('close');
	}

}

