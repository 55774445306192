























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IEmulator } from 'eaasi-admin';

@Component({
	name: 'EmulatorList'
})
export default class EmulatorList extends Vue {

	/* Props
	============================================*/

	@Prop({type: Array, required: true})
	readonly list: IEmulator[]
}

