

















































import {ILegend} from '@/types/Legend';
import Vue from 'vue';
import { Component, Prop} from 'vue-property-decorator';
import { IEnvironment, IDrive, IEditableDrive } from '@/types/Resource';
import { ILabeledEditableItem, ILabeledItem } from '@/types/ILabeledItem';
import EditableLabeledItemList from '../shared/EditableLabeledItemList.vue';
import ResourceDetailsSummary from '../shared/ResourceDetailsSummary.vue';
import ConfiguredDrives from './ConfiguredDrives.vue';
import ConfigureNetwork from './ConfigureNetwork.vue';
import ConfigureEmulator from './ConfigureEmulator.vue';
import LabeledItemList from '@/components/global/LabeledItem/LabeledItemList.vue';
import ModeToggle from '../shared/ModeToggle.vue';
import SectionLegend from '@/components/global/LegendElement.vue';

@Component({
    name: 'EnvironmentMetadataSection',
    components: {
        EditableLabeledItemList,
		ResourceDetailsSummary,
		LabeledItemList,
		ConfiguredDrives,
		ConfigureNetwork,
		ConfigureEmulator,
		ModeToggle,
		SectionLegend
    }
})
export default class EnvironmentMetadataSection extends Vue {

	/* Data
    ============================================*/
	editLegend: ILegend = {
		color: 'yellow',
		text: 'Edited Field'
	}

    /* Props
    ============================================*/
	@Prop({ type: Object as () => IEnvironment, required: true })
	resource: IEnvironment;

	@Prop({ type: String })
	activeMode: String;

	@Prop({ type: Array as () => ILabeledEditableItem[] })
	emulatorLabeledItems : ILabeledEditableItem[];

	@Prop({ type: Array as () => ILabeledEditableItem[] })
	uiOptionLabeledItems: ILabeledEditableItem[];

	@Prop({ type: Array as () => ILabeledEditableItem[] })
	networkLabeledItems: ILabeledEditableItem[];

	@Prop({ type: Array as () => ILabeledItem[] })
	installedSoftware: ILabeledItem[];

	@Prop({ type: Array as () => ILabeledEditableItem[] })
	configMachineLabeledItems: ILabeledEditableItem[];

    /* Computed
    ============================================*/
	get drives(): IDrive[] {
        return this.resource.drives ? this.resource.drives : [];
    }

	get isEditMode(): boolean {
		return this.activeMode === 'Edit Mode';
	}

	get isLinuxRuntimeSelected(): boolean {
		if(this.emulatorLabeledItems && this.emulatorLabeledItems.length) {
			const item = this.emulatorLabeledItems.find(i => i.property === 'linuxRuntime');
			if (item) return item.value;
		} else return false;
	}

    /* Methods
	============================================*/
	updateDrives(drives: IEditableDrive[]) {
		this.resource.drives = drives;
	}

}
