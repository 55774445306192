















import { Component, Vue } from 'vue-property-decorator';
import RssService from '@/services/RssService';
import DashboardBlogEntry from '@/components/dashboard/DashboardBlogEntry.vue';
import {IBlogArticleLink} from '@/types/RssFeed';

let rssService = RssService;

@Component({
	name: 'DashboardBlogFeed',
	components: {DashboardBlogEntry}
})
export default class DashboardBlogFeed extends Vue {
	blogTitle: string = '';
	blogArticles: IBlogArticleLink[] = []

	async buildBlogFeed() {
		let res = await rssService.getBlogFeed();
		if(!res) return;
		this.blogTitle = res.blogTitle;
		this.blogArticles = res.articleLinks;
	}

	created() {
		this.buildBlogFeed();
	}
}
