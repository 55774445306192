
















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
	name: 'EnvironmentConfigureHardware'
})
export default class EnvironmentConfigureHardware extends Vue {

	/* Props
	============================================*/

	/* Data
	============================================*/

	hardware: number = 2; // TODO: Temporary
	hardwareOptions = [
		{
			label: 'Low-End Hardware',
			description: 'Limited graphics card, etc',
			value: 1
		},
		{
			label: 'Basic Hardware',
			description: 'Basic graphics card, etc',
			value: 2
		},
		{
			label: 'High-End Hardware',
			description: 'Advanced graphics card, etc',
			value: 3
		}
	]
}

