









	import {ILegend} from '@/types/Legend';
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';

	/**
	 * LegendElement are rectangular elements that can be used to
	 * provide explanation of particular color use in a view.
	 * The name `section-legend` is used to avoid conflict with
	 * native HTML `legend` tag.
	 * @example ../docs/LegendElement.Example.md
	 */
	@Component({
		name: 'LegendElement'
	})
	export default class LegendElement extends Vue {
		/* Props
        ============================================*/
		/**
		 * The legend data
		 */
		@Prop({type: Object as () => ILegend, required: true})
		readonly data: ILegend;
	}
