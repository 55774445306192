

















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

/**
 * A general button for UI interaction
 * @example ../docs/UiButton.Example.md
 */
@Component({
	name: 'UiButton'
})
export default class UiButton extends Vue {

	/* Props
	============================================*/

	/**
	 * Make display type: block
	 */
	@Prop({type: Boolean, required: false})
	readonly block: boolean;

	/**
	 * Disables minimum width
	 */
	@Prop({type: Boolean, required: false})
	readonly collapse: boolean;

	/**
	 * Optional color preset
	 */
	@Prop({type: String, required: false, default: 'default'})
	readonly colorPreset: String;

	/**
	 * Font icon name
	 */
	@Prop({type: String, required: false})
	readonly icon: string;

	/**
	 * Show icon on right side of slot (instead of left)
	 */
	@Prop({type: Boolean, required: false})
	readonly iconRight: boolean;

	/**
	 * Shows a sublabel on the button
	 */
	@Prop({type: String, required: false})
	readonly subLabel: string;

	/**
	 * Adds a size class
	 */
	@Prop({type: String, required: false})
	readonly size: string;
}
