

















































import Vue from 'vue';
import { Get } from 'vuex-pathify';
import { Component, Prop } from 'vue-property-decorator';
import User from '@/models/admin/User';

@Component({
    name: 'ModeToggle'
})
export default class ModeToggle extends Vue {

    /* Props
	============================================*/
	@Prop({ type: String })
	toggleValue: string;

	@Prop({ type: Array as () => String[], required: true })
	toggleOptions: String[]

	@Prop({ type: Boolean, default: false })
	supressConfirmation: boolean;

	@Prop({ type: Boolean, default: false })
	isPublic: boolean;

    /* Computed
    ============================================*/
	@Get('loggedInUser')
    loggedInUser: User;

    get userCanEdit() {
		return this.loggedInUser.userHasEditPermissions && this.toggleOptions.length > 1;
	}

	get isEditMode(): boolean {
		return this.toggleValue === 'Edit Mode';
	}

    /* Data
    ============================================*/
    showEditConfirmModal: Boolean = false;
	editable: Boolean = false;

    /* Methods
    ============================================*/
    confirmEdit() {
		this.editable = true;
		this.showEditConfirmModal = false;
		this.$emit('mode-change', this.toggleOptions[1]);
	}

    onToggleValueChange(val) {
		if (val === 'Edit Mode' && !this.editable && !this.supressConfirmation) {
			this.showEditConfirmModal = true;
		} else {
			this.$emit('mode-change', val);
		}
    }

}
