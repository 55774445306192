












































import {PhysicalFormat} from '@/types/Resource';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ResourceImportFile from '@/models/import/ResourceImportFile';
import {Sync} from 'vuex-pathify';

@Component({
	name: 'ResourceFileListItem',
})
export default class ResourceFileListItem extends Vue {

	/* Props
	============================================*/
	@Prop({type: Object as () => File, required: true})
	readonly file: ResourceImportFile;

	/* Computed
	============================================*/

	@Sync('import/selectedFiles')
	selectedFiles: ResourceImportFile[];

	get selected(): boolean {
		return !!this.selectedFiles.find(f => f.name === this.file.name);
    }

    set selected(isSelected: boolean) {
		if (isSelected) {
			this.selectedFiles.push(this.file);
		}
	}

	/* Methods
	============================================*/
	updateSortIndex() {
		this.$emit('sort', { file: this.file });
	}

	setSortIndex(value: string | number) {
		this.file.sortIndex = Number(value);
	}

	onToggle(isChecked: boolean) {
		if (!isChecked) {
			this.selectedFiles = this.selectedFiles.filter(f => f.name !== this.file.name);
		}
	}

	handleChange(fieldValue: string) {
		// Only update other selected files if this file is also selected
		let format = fieldValue as PhysicalFormat;
		if (this.selected) {
			this.selectedFiles.forEach((file) => {
				file.physicalFormat = format;
			});
		}
	}
}

