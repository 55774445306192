





















import Vue from 'vue';
import { Get } from 'vuex-pathify';
import { Component } from 'vue-property-decorator';
import {archiveTypes, translatedIcon} from '@/utils/constants';
import { ILabeledItem } from '@/types/ILabeledItem';
import { ITag } from '@/types/Tag';
import EmulationProjectEnvironment from '@/models/emulation-project/EmulationProjectEnvironment';
import LabeledItem from '@/components/global/LabeledItem/LabeledItem.vue';

@Component({
	name: 'EnvironmentCard',
	components: {
		LabeledItem
	}
})
export default class EnvironmentCard extends Vue {

	/* Props
	============================================*/

	/* Computed
	============================================*/
	@Get('emulationProject/environment')
	environment: EmulationProjectEnvironment;

	get environmentTags(): ITag[] {
		let tagGroup = [];
		tagGroup.push({
			text: 'Content Environment',
			icon: translatedIcon('file'),
			color: 'white'
		});
		if (this.environment.archive === archiveTypes.PUBLIC) {
			tagGroup.push({
				icon:  translatedIcon('map-marker'),
				color: 'white',
				text: 'Saved'
			});
		} else if (this.environment.archive === archiveTypes.DEFAULT) {
			tagGroup.push({
				icon:  translatedIcon('cloud-download'),
				color: 'white',
				text: 'Private'
			});
		}
		return tagGroup;
	}

	/* Data
	============================================*/
	labeledItems: ILabeledItem[] = [];

	/* Methods
	============================================*/
	setLabeledItems(): void {
		let labeledItems: ILabeledItem[] = [];
		if (this.environment.os) {
			labeledItems.push({
				label: 'Operating System',
				value: this.environment.os
			});
		}
		if (this.environment.installedSoftwareIds?.length) {
			labeledItems.push({
				label: 'Installed Software:',
				value: this.environment.installedSoftwareIds.map(software => software.label).join(', '),
			});
		}
		if (this.environment.emulator) {
			labeledItems.push({
				label: 'Machine',
				value: this.environment.emulator
			});
		}
		if (this.environment.installedSoftwareIds?.length) {
			labeledItems.push({
				label: 'Content',
				value: this.environment.installedSoftwareIds.map(software => software.label).join(', '),
			});
		}
		this.labeledItems = labeledItems;
	}

	/* Lifecycle Hooks
	============================================*/
	beforeMount() {
		this.setLabeledItems();
	}

}
