


























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ResourceImportPath } from '@/types/Import';
import { Get, Sync } from 'vuex-pathify';
import GeneralMetadata from './EnvironmentImportGeneralMetadata.vue';
import MetadataFast from './EnvironmentImportMetadataFast.vue';
import OsMetadata from './EnvironmentImportOSMetadata.vue';
import SoftwareProductForm from '@/components/import/software/SoftwareProductMetadataForm.vue';
import SoftwareVersionForm from '@/components/import/software/SoftwareVersionForm.vue';

@Component({
	name: 'EnvironmentImportMetadata',
	components: {
		GeneralMetadata,
		MetadataFast,
		OsMetadata,
		SoftwareProductForm,
		SoftwareVersionForm
	}
})
export default class EnvironmentImportMetadata extends Vue {

	/* Computed
	============================================*/

	@Get('import/importPath')
	readonly importPath: ResourceImportPath;

	@Sync('import/importStep')
	step: number;

	/* Data
	============================================*/

	versions: any[] = [{id: 1}];  // TODO


	/* Methods
	============================================*/

	addSoftwareVersion() {
		// TODO:
		let id = Math.max.apply(null, this.versions.map(x => x.id)) + 1;
		this.versions.push({id});
	}


	/* Lifecycle Hooks
	============================================*/
	created() {
		this.$store.commit('import/SET_IS_IMPORTED_ENVIRONMENT', true);
	}
}

