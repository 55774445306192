

































































































	import Vue from 'vue';
	import {archiveTypes, resourceTypes, translatedIcon} from '@/utils/constants';
	import { Component, Prop } from 'vue-property-decorator';
	import { IEaasiResourceSummary } from '@/types/Resource';
	import { jsonCopy } from '@/utils/functions';
	import eventBus from '@/utils/event-bus';

	@Component({
		name: 'ResourceDetailsSummary',
	})
	export default class ResourceDetailsSummary extends Vue {

		/* Props
        ============================================*/
		@Prop({ type: Object as () => IEaasiResourceSummary, required: true})
		summaryData: IEaasiResourceSummary;

		@Prop({ type: Boolean})
		readonly: Boolean;

		/* Computed
        ============================================*/
		get isPublicArchive() {
			return this.summaryData.archive === archiveTypes.PUBLIC;
		}

		get isPrivateArchive() {
			return this.summaryData.archive === archiveTypes.DEFAULT;
		}

		get isRemoteArchive() {
			return this.summaryData.archive === archiveTypes.REMOTE;
		}

		get isSoftware() {
			return this.summaryData.resourceType === resourceTypes.SOFTWARE;
		}

		get isEnvironment() {
			return this.summaryData.envId != null ||
				this.summaryData.resourceType === resourceTypes.ENVIRONMENT;
		}

		get isContent() {
			return this.summaryData.resourceType === resourceTypes.CONTENT;
		}

		get contentIcon() {
			return translatedIcon('file');
		}

		get softwareIcon() {
			return translatedIcon('disk');
		}

		get environmentIcon() {
			return translatedIcon('config-environment');
		}

		get publicNetworkIcon() {
			return translatedIcon('public-network');
		}

		get savedLocallyIcon() {
			return translatedIcon('map-marker');
		}

		get privateIcon() {
			return translatedIcon('lock');
		}

	/* Data
	============================================*/

		localTitle: string;
		localDescription: string;

		saveLocalData() {
			this.localTitle = jsonCopy(this.summaryData.title);
			this.localDescription = this.summaryData.description ? jsonCopy(this.summaryData.description) : null;
			this.$forceUpdate();
		}

	/* Lifecycle Methods
    ============================================*/
		created() {
			this.saveLocalData();
			eventBus.$on('resource-details:refresh', this.saveLocalData);
		}

	}

