




















import BaseFormField from './BaseFormField.vue';
import { Component, Prop } from 'vue-property-decorator';
import FormFieldWrapper from './FormFieldWrapper.vue';

/**
 * A Checkbox form element
 *
 * @example ../../docs/Checkbox.Example.md
 *
 */
@Component({
	name: 'Checkbox',
	components: {
		FormFieldWrapper
	}
})
export default class Checkbox extends BaseFormField {

	@Prop({type: Boolean, required: true})
	readonly value: boolean;

	@Prop({ type: Boolean })
	readonly disabled: boolean;

	@Prop({ type: Boolean, default: false })
	readonly indeterminate: boolean;

	handleChange(event) {
		if (this.disabled) return;
		this.$emit('change', event);
		this.$emit('input', !this.value);
	}

	get wrapperPropsExtended() {
		return {
			...this.wrapperProps,
			hideLabel: true
		};
	}

}

