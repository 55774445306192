





































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ResourceImportPath } from '@/types/Import';

@Component({
	name: 'ImportPathSelect',
})
export default class ImportPathSelect extends Vue {

	/* Props
	============================================*/

	/**
	 * ResourceImportPath value to be used with v-model directive
	 */
	@Prop({type: String, required: true})
	readonly value: ResourceImportPath | null;

}

