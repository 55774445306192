






import Vue from 'vue';
import { Component } from 'vue-property-decorator';

/**
 * A thin functional wrapper around a div with class `row`
 */
@Component({
	name: 'Row',
})
export default class Row extends Vue {}

