
















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

/**
 * Tags are rectangular elements that can be used to
 * add context to adjacent or surrounding content.
* @example ../docs/Tag.Example.md
*/
@Component({
	name: 'Tag'
})
export default class Tag extends Vue {
	/* Props
    ============================================*/
	/**
	 * The text displayed in the Tag
	 */
    @Prop({type: String, required: true})
    readonly text: string;

    /**
	 * The custom EaaSI UI icon name displayed in the Tag
	 */
    @Prop({type: String, required: false})
    readonly icon: string;

	/**
	 * The FontAwesome icon name displayed in the Tag
	 */
	@Prop({type: String, required: false})
	readonly externalIcon: string;

	/**
	 * The color of the Tag
	 */
    @Prop({type: String, required: false})
    readonly color: string;
}
