






























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ILabeledEditableItem } from '@/types/ILabeledItem';
import EditableCheckboxItem from '../shared/EditableCheckboxItem.vue';
import EditableSelectList from '../shared/EditableSelectList.vue';
import EditableTextItem from '../shared/EditableTextItem.vue';

@Component({
    name: 'ConfigureEmulator',
    components: {
        EditableCheckboxItem,
        EditableSelectList,
        EditableTextItem
    }
})
export default class ConfigureEmulator extends Vue {

    /* Props
    ============================================*/
    @Prop({ type: Array as () => ILabeledEditableItem[], required: true })
	emulatorItems: ILabeledEditableItem[]

    @Prop({ type: Boolean })
    readonly: Boolean;

    /* Computed
    ============================================*/
    get emulator() {
        return this.emulatorItems.find(i => i.property === 'emulator');
    }
    get nativeConfig() {
        return this.emulatorItems.find(i => i.property === 'nativeConfig');
    }
    get linuxRuntime() {
        return this.emulatorItems.find(i => i.property === 'linuxRuntime');
    }
    get containerVersion() {
        return this.emulatorItems.find(i => i.property === 'containerVersion');
    }
    get os() {
        return this.emulatorItems.find(i => i.property === 'os');
    }
    get time() {
        return this.emulatorItems.find(i => i.property === 'time');
    }

}
