


















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IEaasiTab } from 'eaasi-nav';

@Component({
	name: 'SlideMenuControlButtons'
})
export default class SlideMenuControlButtons extends Vue {

	/* Props
	============================================*/
	@Prop({ type: Array as () => IEaasiTab[], required: true })
	readonly tabs: IEaasiTab[];

	get filteredTabs() {
		return this.tabs.filter(tab => !tab.disabled);
	}

}
