





















































import User from '@/models/admin/User';
import DashboardResourceList from '@/components/dashboard/DashboardResourceList.vue';
import ResourceList from '@/components/resources/ResourceList.vue';
import { IBookmark } from '@/types/Bookmark';
import { IResourceSearchResponse } from '@/types/Search';
import { Component, Vue } from 'vue-property-decorator';
import { Get } from 'vuex-pathify';

@Component({
	name: 'DashboardLinks',
	components: {
		ResourceList,
		DashboardResourceList
	}
})
export default class DashboardLinks extends Vue {
	goToExploreResources() {
		this.$router.push({ name: 'Explore Resources' });
	}

	goToMyResources() {
		this.$router.push({ name: 'My Resources' });
	}

	@Get('loggedInUser')
	user: User;

	@Get('resource/result')
	bentoResult: IResourceSearchResponse;

	@Get('bookmark/bookmarks')
	bookmarks: IBookmark[];

	async search() {
		await this.$store.dispatch('bookmark/getBookmarks', this.user.id);
		await this.$store.dispatch('resource/searchResources', this.bookmarks);
	}

	get myResources() {
		if (this.bentoResult) {
			let envResults = this.bentoResult.environments;
			let swResults = this.bentoResult.software;

			let envs = envResults.result.filter(r => this.bookmarks.some(b => b.resourceId === r.envId));
			let software = swResults.result.filter(s => this.bookmarks.some(b => b.resourceId === s.id));
			let myResources = [...envs, ...software];

			return { result: myResources };
		}
		return {};
	}

	get nodeResources() {
		if (this.bentoResult) {
			let envResults = this.bentoResult.environments;
			let envs = envResults.result.filter(r => r.archive === 'default');
			let myResources = [...envs,];

			return { result: myResources };
		}
		return {};
	}

	mounted() {
		this.search();
	}
}
