





































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import EaasiIcon from '@/components/global/icons/EaasiIcon.vue';
import { ResourceType } from '@/types/Resource';
import { IEaasiResource } from '@/types/Resource';
import { IEaasiSearchResponse } from '@/types/Search';
import { MIN_SEARCH_RESULT_LIMIT } from '@/utils/constants';

@Component({
	name: 'BentoHeader',
	components: { EaasiIcon },
})
export default class BentoHeader extends Vue {

	/* Props
	============================================*/
	@Prop({ type: String, required: true })
	readonly type: ResourceType;

	@Prop({type: Object as () => IEaasiSearchResponse<IEaasiResource>, required: true})
	readonly result: IEaasiSearchResponse<IEaasiResource>;

	/* Computed
	============================================*/
	get label(): string {
		return this.type + ' Resources';
	}

	get icon(): string {
		if (this.type === 'Software') return 'disk';
		if (this.type === 'Content') return 'disk';
		if (this.type === 'Image') return 'disk';
		return 'config-environment';
	}

	get description(): string {
		if (this.type === 'Software') {
			return 'Results for software install media that can be attached to environments so you can install the software.';
		}
		if (this.type === 'Content') {
			return 'Files and objects from digital collections to be added to an environment for access.';
		}
		if (this.type === 'Environment') {
			return 'Pre-configured environments ready for access or as a base environment for new configurations.';
		}
		return '';
	}

	/* Data
	============================================*/
	minSearchResultLimit: Number = MIN_SEARCH_RESULT_LIMIT;
}

