















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IEaasiTab } from 'eaasi-nav';

/**
 * A component for performing tabbed navigation
 * @example ../docs/TabbedNav.Example.md
 */
@Component({
	name: 'TabbedNav'
})
export default class TabbedNav extends Vue {

	/* Props
	============================================*/

	// List of tabs
	@Prop({type: Array, required: true})
	readonly tabs: IEaasiTab[];

	// Active tab label (use v-model)
	@Prop({type: String, required: true})
	readonly value: string;

	@Prop({ type: String, default: 'default' })
	readonly colorPreset: string;

	/* Methods
	============================================*/

	selectTab(tab: IEaasiTab) {
		if (tab.label === this.value) return;
		return !tab.disabled && this.$emit('input', tab.label);
	}
}

