




























import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({
    name: 'DateRange'
})
export default class DateRange extends Vue {

    /* Props
	============================================*/
	@Prop({ type: Object as () => IDateRange, required: true })
	value: IDateRange;

    /* Computed
	============================================*/

    /* Data
	============================================*/
	isStartDateOpen: boolean = false;
	isEndDateOpen: boolean = false;

    /* Methods
	============================================*/
	onStartDateOpen(status: boolean) {
		this.isStartDateOpen = status;
	}

	onEndDateOpen(status: boolean) {
		this.isEndDateOpen = status;
	}

    /* Lifecycle Hooks
	============================================*/
	mounted() {

	}

}

export interface IDateRange {
	startDate: string;
	endDate: string;
}
