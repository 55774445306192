










import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'UiNotification'
})
export default class UiNotification extends Vue {

    /* Props
    ============================================*/
    @Prop({ type: String, required: true })
    readonly label: String;

    @Prop({ type: String, default: 'success' })
    readonly colorPreset: String;

    get icon(): String {
        switch (this.colorPreset) {
            case 'success':
                return 'check';
                break;
            case 'danger':
                return 'times-circle';
                break;
            case 'info':
                return 'info-circle';
                break;
            case 'warning':
                return 'exclamation-triangle';
                break;
        }
    }

}
