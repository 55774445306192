



















import Vue from 'vue';
import ResourceSearchQuery from '@/models/search/ResourceSearchQuery';
import { Component } from 'vue-property-decorator';
import { Sync } from 'vuex-pathify';

@Component({
    name: 'NoSearchResult'
})
export default class  extends Vue {

    /* Computed
    ============================================*/

    @Sync('resource/query')
    query: ResourceSearchQuery;

    /* Methods
    ============================================*/
    async clearSearch() {
        this.query = new ResourceSearchQuery();
        await this.$store.dispatch('resource/searchResources');
	}

	// getNodeResources() {
		// TODO: Search for all resources with archiveID === public
	// }

}
