














import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { IDrive, IEaasiResource, IResourceDrive } from '../../../types/Resource';
import { resourceTypes } from '@/utils/constants';
import ContentResourceCard from '@/components/resources/ContentResourceCard.vue';
import SoftwareResourceCard from '@/components/resources/SoftwareResourceCard.vue';

@Component({
	name: 'DriveCard',
	components: {
		ContentResourceCard,
		SoftwareResourceCard,
	}
})
export default class DriveCard extends Vue {

	/* Props
	============================================*/
	@Prop({ type: String, required: true })
	readonly label: string;

}
