












import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import EaasiIcon from '@/components/global/icons/EaasiIcon.vue';

@Component({
    name: 'CheckboxInfo',
    components: {
        EaasiIcon
    }
})
export default class CheckboxInfo extends Vue {

    /* Props
    ============================================*/
    @Prop({ type: Boolean, required: true })
    readonly value: boolean;

    @Prop({ type: String, required: false })
    readonly label: string;

    @Prop({ type: String })
    readonly infoText: string;

    /* Computed
    ============================================*/

    /* Data
    ============================================*/

    /* Methods
    ============================================*/

    /* Lifecycle Hooks
    ============================================*/

}
