



























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';


@Component({
	name: 'SoftwareVersionForm',
})
export default class SoftwareVersionForm extends Vue { }

