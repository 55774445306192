













import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

export type InfoType = 'error';

@Component({
	name: 'InfoMessage'
})
export default class InfoMessage extends Vue {

	/* Props
	============================================*/
	@Prop({ type: String as () => InfoType, required: true })
	readonly type: InfoType;

	@Prop({ type: String, required: true })
	readonly message: string;

	@Prop({ type: String, required: false })
	readonly description: string;

	@Prop({ type: Boolean, default: false })
	readonly collapsible: boolean;

	showDescription: boolean = true;

	toggle() {
		this.showDescription = !this.showDescription;
	}

}
