
















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ILabeledItem } from '@/types/ILabeledItem';
import { jsonCopy } from '@/utils/functions';
import eventBus from '@/utils/event-bus';

@Component({
    name: 'EditableCheckboxItem'
})
export default class EditableCheckboxItem extends Vue {

    /* Props
    ============================================*/
    @Prop({ type: Object as () => ILabeledItem })
    readonly item: ILabeledItem;

    @Prop({ type: Boolean })
    readonly readonly: Boolean;

    /* Computed
    ============================================*/
    get displayValue() {
    	if (this.item.value === false) {
    		return 'FALSE';
		}
    	return 'TRUE';
	}

	get displayColor() {
		if (this.item.value === false) {
			return 'red';
		}
		return 'green';
	}

	get displayIcon() {
		if (this.item.value === false) {
			return 'fa-times';
		}
		return 'fa-check';
	}

	/* Data
	============================================*/
	localItem: ILabeledItem = null;

	refresh() {
		this.localItem = jsonCopy(this.item);
		this.$forceUpdate();
	}

	/* Lifecycle Methods
		============================================*/
	created() {
		this.refresh();
		eventBus.$on('editable-item:refresh', this.refresh);
	}

}
