











import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { ILabeledEditableItem, ILabeledItem } from '../../../types/ILabeledItem';
import { ITemplate } from '../../../types/Import';
import LabeledItemList from '@/components/global/LabeledItem/LabeledItemList.vue';

@Component({
    name: 'SystemTemplateDetails',
    components: {
        LabeledItemList
    }
})
export default class SystemTemplateDetails extends Vue {

	/* Props
    ============================================*/
    @Prop({ type: Object as () => ITemplate, required: true })
    template: ITemplate;

	/* Computed
	============================================*/
	get templateDetailItems(): ILabeledItem[] {
        let items = [
            {
                label: 'Emulator',
                value: this.template.emulator.bean
            },
            {
                label: 'CPU Architecture',
                value: this.template.arch
            }
        ];
		return items;
	};

	/* Data
	============================================*/

	/* Methods
	============================================*/

	/* Lifecycle Hooks
	============================================*/

}
