




















import Vue from 'vue';
import { Component } from 'vue-property-decorator';


@Component({
	name: 'SoftwareProductMetadataForm',
})
export default class SoftwareProductMetadataForm extends Vue { }

