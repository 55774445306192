




















import {Component, Watch} from 'vue-property-decorator';
import BaseFormField from './BaseFormField.vue';
import FormFieldWrapper from './FormFieldWrapper.vue';

/**
 * A select list form input
 * @example ../../docs/SelectList.Example.md
 */
@Component({
	name: 'SelectList',
	components: {
		FormFieldWrapper
	}
})
export default class SelectList extends BaseFormField { }

