






























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import SelectableContainer from '@/components/global/selectable-container/SelectableContainer.vue';
import { defaultOsList } from '@/utils/constants';
import { IUIOsItem } from '@/types/Resource';

@Component({
	name: 'OsPicker',
	components: {
		SelectableContainer
	}
})
export default class OsPicker extends Vue {

	/* Props
	============================================*/
	@Prop({ type: Array as () => IUIOsItem[], default: () => defaultOsList })
	readonly osList: IUIOsItem[];

	@Prop({ type: Object as () => IUIOsItem })
	readonly selectedOs: IUIOsItem;

	@Prop({ type: Boolean, default: false })
	readonly list: boolean;

	@Prop({ type: Boolean, default: false })
	readonly readonly: boolean;

	/* Computed
	============================================*/
	get selectedOsTitle(): string {
		return this.selectedOs ? this.selectedOs.title : null;
	}

	get selectedOsValue(): string {
		return this.selectedOs ? this.selectedOs.value : null;
	}

	/* Data
	============================================*/

	/* Methods
	============================================*/
	onChange(osName: string) {
		return this.$emit('input', this.osList.find(os => os.title === osName));
	}

	/* Lifecycle Hooks
	============================================*/

}
