
















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ILabeledEditableItem } from '@/types/ILabeledItem';
import EditableCheckboxItem from '../shared/EditableCheckboxItem.vue';
import EditableSelectList from '../shared/EditableSelectList.vue';
import EditableTextItem from '../shared/EditableTextItem.vue';
import { IOsItem } from '@/types/Resource';
import {operatingSystems} from '@/models/admin/OperatingSystems';

@Component({
    name: 'SoftwareProperties',
    components: {
        EditableCheckboxItem,
        EditableSelectList,
        EditableTextItem
    }
})
export default class SoftwareProperties extends Vue {

    /* Props
    ============================================*/
    @Prop({ type: Array as () => ILabeledEditableItem[], required: true })
    items: ILabeledEditableItem[];

    @Prop({ type: Array as () => IOsItem[] })
    osItems: IOsItem[];

    @Prop({ type: Boolean })
    readonly: Boolean;

    /* Computed
    ============================================*/
	get objectId(): ILabeledEditableItem {
		return this.items.find(i => i.property === 'id');
	}
	get objectLabel(): ILabeledEditableItem {
		return this.items.find(i => i.property === 'label');
	}
	get licenseInformation(): ILabeledEditableItem {
        return this.items.find(i => i.property === 'licenseInformation');
    }
    get allowedInstances(): ILabeledEditableItem {
        return this.items.find(i => i.property === 'allowedInstances');
    }
    get isOperatingSystem(): ILabeledEditableItem {
        return this.items.find(i => i.property === 'isOperatingSystem');
    }
    get nativeFMTs(): ILabeledEditableItem {
        return this.items.find(i => i.property === 'nativeFMTs');
    }

    /* Data
    ============================================*/
    selectedOs: string = null;
    readonly operatingSystems = operatingSystems;

    /* Methods
    ============================================*/
    addOsPreset() {
        const selectedOs = this.operatingSystems.find(os => os.id === this.selectedOs);
        this.nativeFMTs.value.forEach(i => this.remove(i));
        selectedOs.puids.forEach(i => {
            if (!this.nativeFMTs.value.some(fmt => fmt === i.puid)) this.add(i.puid);
        });
    }

    add(fmt: string) {
        if (this.readonly) return;
        return this.$emit('add-fmt', fmt);
    }

    remove(fmt: string) {
        if (this.readonly) return;
        return this.$emit('remove-fmt', fmt);
    }

}
