











































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IEaasiResource, IDriveSetting } from '@/types/Resource';
import { resourceTypes } from '@/utils/constants';
import ContentResourceCard from '@/components/resources/ContentResourceCard.vue';
import SoftwareResourceCard from '@/components/resources/SoftwareResourceCard.vue';
import DriveCard from './DriveCard.vue';

@Component({
	name: 'DriveResourceCard',
	components: {
		ContentResourceCard,
		DriveCard,
		SoftwareResourceCard,
	}
})
export default class DriveResourceCard extends Vue {

	/* Props
	============================================*/
	@Prop({ type: Object as () => IDriveSetting, required: true })
	readonly driveSetting: IDriveSetting;

	@Prop({ type: Array as () => IEaasiResource[] })
	readonly resources: IEaasiResource[];

	/* Computed
	============================================*/
	get resource(): IEaasiResource {
		if (!this.driveSetting.objectId) return null;
		return this.resources.find(resource => resource.id === this.driveSetting.objectId || resource.id === this.driveSetting.imageId);
	}

	get hasResource(): boolean {
		return this.resource != null;
	}

	get isSoftware() {
		return this.hasResource && this.resource.resourceType === resourceTypes.SOFTWARE;
	}

	get isContent() {
		return this.hasResource && this.resource.resourceType === resourceTypes.CONTENT;
	}

	get driveCardLabel(): string {
		return `${this.driveSetting.drive.type}`;
	}

	removeResource() {
		this.driveSetting.objectId = null;
	}

}
