






import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

/**
 * A sliding menu positioned on the right side of the main UI
 */
@Component({
	name: 'SlideMenu',
})
export default class SlideMenu extends Vue {

	/* Props
	============================================*/

	/**
	 * The CSS easing rule for the open animation
	 */
	@Prop({type: String, required: false, default: 'ease-out'})
	readonly easing: string;

	/**
	 * Opens the menu when true
	 */
	@Prop({type: Boolean, required: true})
	readonly open: boolean;

	/**
	 * The open/close animation speed in seconds
	 */
	@Prop({type: Number, default: 0.4, required: false})
	readonly speed: number;

	/* Computed
	============================================*/

	get styles(): any {
		let { speed, easing } = this;
		return {
			transition: `transform ${speed}s ${easing}`
		};
	}

}

