













































import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import { IDrive } from '@/types/Resource';

@Component({
    name: 'DriveEditModal'
})
export default class DriveEditModal extends Vue {

    /* Props
    ============================================*/
    @Prop({ type: Object as () => IDrive })
    drive: IDrive;

    /* Methods
    ============================================*/
    save() {
        this.$emit('save', this.drive);
    }

    /* Watchers
    ============================================*/
    @Watch('drive.type')
	onDriveTypeChange(updated, _) {
    	if (updated === 'floppy') {
    		this.drive.bus = '0';
			this.drive.unit = '0';
			this.drive.filesystem ='fat12';
			this.drive.iface = 'floppy';
		}
	}
}
