












import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {ILabeledItem} from '@/types/ILabeledItem';
import LabeledItem from './LabeledItem.vue';

/**
 * A list of LabeledDetails components for use as a categorical display of similar elements
*/
@Component({
	name: 'LabeledItemList.vue',
	components: {
		LabeledItem
	}
})
export default class LabeledDetailsList extends Vue {
	@Prop({type: Array as () => ILabeledItem[], required: true })
	readonly labeledItems: ILabeledItem[]

	@Prop({ type: String, required: false })
	readonly title: string;
}
