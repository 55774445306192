
















































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';


@Component({
	name: 'SoftwareRequirementsForm',
})
export default class SoftwareRequirementsForm extends Vue { }

