

























import { Component, Prop } from 'vue-property-decorator';
import BaseFormField from './BaseFormField.vue';
import FormFieldWrapper from './FormFieldWrapper.vue';
import { IRadioOption } from '@/types/Forms';

function isRadioOption(object: any): object is IRadioOption {
	if(typeof object !== 'object') return false;
	return 'label' in object && 'value' in object;
}

/**
 * A Radio button component
 * @example ../../docs/RadioButtons.Example.md
 */
@Component({
	name: 'RadioButtons',
	components: {
		FormFieldWrapper
	}
})
export default class RadioButtons extends BaseFormField {

	/* Props
	============================================*/

	// Whether or not to display the radio buttons inline
	@Prop({type: Boolean, required: false})
	readonly inline: Boolean

	// The shared name attribute
	@Prop({type: String, required: true})
	readonly name: String

	// A list of strings or IRadioButtons to display as radio buttons
	@Prop({type: Array, required: true})
	readonly values: string[] | IRadioOption[]


	@Prop({required: true})
	readonly value: any

	/* Computed
	============================================*/

	get options(): IRadioOption[] {
		if(!this.values.length) return [];
		if(isRadioOption(this.values[0])) {
			return this.values as IRadioOption[];
		}
		let opts = new Array<IRadioOption>();
		for(let i=0;i<this.values.length;i++) {
			opts.push({
				value: this.values[i],
				label: (this.values[i] as string)
			});
		}
		return opts;
	}

	/* Methods
	============================================*/

	changeVal(value) {
		this.$emit('input', value);
	}

	radioIsChecked(value) {
		return this.value === value;
	}

}

