import { render, staticRenderFns } from "./AccessDeniedScreen.vue?vue&type=template&id=6e4fee2d&"
import script from "./AccessDeniedScreen.vue?vue&type=script&lang=ts&"
export * from "./AccessDeniedScreen.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports