












































import Vue from 'vue';
import { Get } from 'vuex-pathify';
import { Component, Prop } from 'vue-property-decorator';
import { IEaasiUser } from 'eaasi-admin';
import { ISoftwarePackage } from '@/types/Resource';
import ResourceSearchQuery from '@/models/search/ResourceSearchQuery';
import { resourceTypes } from '@/utils/constants';

@Component({
    name: 'AddSoftwareModal'
})
export default class AddSoftwareModal extends Vue {

	@Get('loggedInUser')
	user: IEaasiUser;

    /* Data
    ============================================*/
	selectedSoftwareId = null;
    errorMessage: string = null;
    loading: Boolean = false;
    softwareList: ISoftwarePackage[] = [];

    /* Methods
    ============================================*/
    runInEmulator() {
		const software = this.softwareList.find(s => s.id === this.selectedSoftwareId);
        return this.$emit('run-in-emulator', software);
    }

    async init() {
        this.loading = true;
        const query = new ResourceSearchQuery();
        query.userId = this.user.id;
        query.types = [resourceTypes.SOFTWARE];
        query.limit = 1000;

        this.$store.commit('software/SET_QUERY', query);
        const { software } = await this.$store.dispatch('software/searchSoftware');
        if (!software) {
            this.errorMessage = 'Having troubles fetching a list of software packages.';
        }
        this.softwareList = software.result;
        this.loading = false;
	}

    /* Lifecycle Hooks
    ============================================*/
    async beforeMount() {
        await this.init();
    }

}
