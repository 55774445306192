


















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {INumberedStep} from '@/types/NumberedStep';

/**
 * A Step in a NumberedSteps Component
 */
@Component({
	name: 'NumberedStep'
})
export default class Step extends Vue {

	/* Props
	============================================*/

    /**
	 * A step to be completed
	 */
    @Prop({type: Object as () => INumberedStep, required: true})
	step: INumberedStep

	/**
	 * Whether or not the step has been completed
	 */
	@Prop({type: Boolean, required: false})
	readonly complete: boolean

	/**
	 * Whether or not the step is active
	 */
	@Prop({type: Boolean, required: false})
	readonly active: boolean

}
