




















	import Vue from 'vue';
	import { Component, Prop, Watch } from 'vue-property-decorator';
	import EaasiTask from '@/models/task/EaasiTask';
	import { ITaskState } from '@/types/Task';
	import { jsonEquals } from '@/utils/functions';
	import { Get, Sync } from 'vuex-pathify';

	@Component({
		name: 'TaskModal'
	})
	export default class TaskModal extends Vue {

		@Prop({ type: Object as () => EaasiTask, required: false })
		readonly task: EaasiTask;

		/* Computed
		============================================*/
		@Get('task/taskQueue')
		taskQueue: EaasiTask[];

		/* Data
        ============================================*/
		error: string = null;
		success: boolean = false;

		/* Methods
        ============================================*/
		cancel() {
			this.$emit('close');
			this.reset();
		}

		get isComplete() {
			return this.success || this.error;
		}

		reset() {
			this.error = null;
			this.success = false;
		}

		/* Watcher
        ============================================*/
		@Watch('taskQueue', { immediate: true })
		onTaskQue(currentTaskQueue: EaasiTask[]) {
			const currentTask = currentTaskQueue.find(task => task.taskId === this.task.taskId);
			if (currentTask) {
				if (currentTask.isDone) {
					this.$emit('success', currentTask);
					this.success = true;
				}
				return;
			}
			this.success = false;
			this.error = 'Something went wrong, please try again.';
		}

	}

