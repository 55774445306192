














import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

/**
 * A Collapsable content wrapper
 * @example ../docs/Collapsable.Example.md
 */
@Component({
	name: 'Collapsable',
})
export default class Collapsable extends Vue {

	/* Props
	============================================*/

	/**
	 * The default title to display
	 */
	@Prop({type: String, required: true})
	readonly title: string;

	/**
	 * The icon to display as the dropdown trigger
	 */
	@Prop({type: String, required: false, default: 'chevron-down'})
	readonly icon: string = 'chevron-down';

	/**
	 * Optional title to display when open is true
	 */
	@Prop({type: String, required: false})
	readonly openTitle: string;

	/**
	 * Content will mount collapsed when true
	 */
	@Prop({type: Boolean, required: false, default: false})
	readonly collapsed: boolean;

	/**
	 * Use secondary styles
	 */
	@Prop({type: Boolean, required: false})
	readonly secondary: boolean;

	/* Data
	============================================*/

	open: boolean = true;

	/* Computed
	============================================*/

	get activeIcon() {
		if(this.open) return 'chevron-up';
		return this.icon;
	}

	get activeTitle() {
		if(this.open && this.openTitle) return this.openTitle;
		return this.title;
	}

	/* Lifecycle hooks
	============================================*/

	beforeMount() {
		if(this.collapsed) this.open = false;
	}
}

