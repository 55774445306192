
















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IMenuItem } from 'eaasi-nav';

@Component
export default class AdminMenuItem extends Vue {

	/* Props
	============================================*/

	@Prop({type: Object as () => IMenuItem, required: true})
	readonly item: IMenuItem

	/* Methods
	============================================*/

	handleClick() {
		if(!this.item.onClick) return;
		this.item.onClick();
	}

}
