





















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import isValidAlert from '@/types/validators/AlertType.validator';

/**
 * An alert icon + message combo
 * @example ../../docs/Alert.Example.md
 */
@Component({
	name: 'Alert'
})
export default class Alert extends Vue {

	/**
	 * Adds border and border-radius when true)
	 */
	@Prop({type: String, required: false})
	readonly stylePreset: string;

	/**
	 * Apply card styles (padding and background-color)
	 */
	@Prop({type: Boolean, required: false})
	readonly card: boolean;

	/**
	 * Makes details slot collapsible
	 */
	@Prop({type: Boolean, required: false})
	readonly collapsable: boolean;

	/**
	 * Determines the icon and font color
	 */
	@Prop({ validator: isValidAlert, required: false })
	type: AlertType;

	/**
	 * Hides icon when true
	 */
	@Prop({type: Boolean, required: false})
	readonly noIcon: boolean

	/* Getters
	============================================*/

	/**
	 * Color based on AlertType
	 */
	get color() {
		switch(this.type) {
		case 'success':
			return 'green';
		case 'error':
			return 'red';
		case 'info':
			return 'blue';
		case 'warning':
			return 'orange';
		default:
			return 'neutral';
		}
	}

	/**
	 * Return true if details slot is populated
	 */
	get hasDetails(): boolean {
		return !!this.$slots['details'];
	}

	/**
	 * Icon based on AlertType
	 */
	get icon() {
		switch(this.type) {
		case 'success':
			return 'check-circle';
		case 'error':
		case 'warning':
			return 'exclamation-triangle';
		default:
			return 'info-circle';
		}
	}

	/**
	 * Return true if details slot is populated
	 */
	get isCollapsable(): boolean {
		return this.collapsable && this.hasDetails;
	}

	/* Data
	============================================*/

	collapsed: boolean = this.isCollapsable;

	/* Methods
	============================================*/

	collapse() {
		if(!this.isCollapsable) return;
		this.collapsed = !this.collapsed;
	}

};
