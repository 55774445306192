







































































































import eventBus from '@/utils/event-bus';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { IEaasiTab } from 'eaasi-nav';
import { IEnvironment } from '@/types/Resource';
import { Get } from 'vuex-pathify';
import { translatedIcon } from '@/utils/constants';

@Component({
	name: 'EnvironmentMenu',
	components: {
	}
})
export default class EnvironmentMenu extends Vue {
	/* Data
	============================================*/
	newImportDescription: string = '';  // TODO: this will become an object when more metadata is available

	/* Computed
	============================================*/
	@Get('resource/activeEnvironment')
	readonly environment: IEnvironment;

	@Get('import/environment@title')
	readonly importTitle: string;

	@Get('import/isConstructedEnvironment')
	readonly isConstructed: boolean;

	get tabs(): IEaasiTab[] {
		if (this.environment.isImport) {
			return [];
		}
		return [
			{ label: 'Saved Metadata' },
			/*{ label: 'Configure New' }*/
		];
	}

	get configEnvironmentIcon(): string {
		return translatedIcon('config-environment');
	}

	get saveIcon(): string {
		return translatedIcon('fa-save');
	}

	get uploadIcon(): string {
		return translatedIcon('upload');
	}

	tab: string = 'Saved Metadata';

	/**
	 * Computes whether or not to show the details tab
	 */
	get hasDetails() {
		// TODO: Logic for showing details tab
		return true;
	}

	/* Methods
	============================================*/
	/**
	 * Emits an event to save an imported environment
	 */
	saveImport() {
		eventBus.$emit('emulator:saveEnvironmentImport', {
			description: this.newImportDescription,
			title: this.environment.title
		});
	}

	mounted() {
		if (this.environment.isImport) {
			this.tab = 'New Import';
		}
	}
}

