




















import {Component, Prop, Vue} from 'vue-property-decorator';
import {IBlogArticleLink} from '@/types/RssFeed';


@Component({
	name: 'DashboardBlogEntry'
})
export default class DashboardBlogEntry extends Vue {

	@Prop({ type: Object as () => IBlogArticleLink})
	entry: IBlogArticleLink;
}
