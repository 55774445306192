







import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
	name: 'HeaderMenuItem'
})
export default class HeaderMenuItem extends Vue {

	/* Props
	============================================*/

	/**
	 * The text to display in the trigger element
	 */
	@Prop({type: String, required: true})
	readonly label: string;

	/**
	 * The icon to display in the trigger element
	 */
	@Prop({type: String, required: false})
	readonly icon: string;

}

