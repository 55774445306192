

































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {translatedIcon} from '@/utils/constants';

/**
 * A Card providing user interface to Attach a Resource
 * @example ../docs/AttachResourceCard.Example.md
 */
@Component({
	name: 'AttachResourceCard'
})
export default class AttachResourceCard extends Vue {
		/**
		 * Provides the title of the Resource Type to be attached
		 */
		@Prop({ type: String, required: true })
		title: string;

		searchForEnvironment() {
		}

		openMyResources() {
		}

		get paperclipIcon() {
			return translatedIcon('paperclip');
		}
}
