














import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

/**
 * Wrapper for form fields.  Provides a label and error alert for a slotted field.
 * Provides two slots, a default and a named slot `below-field`
 * @example ../../docs/FormFieldWrapper.Example.md
 */
@Component({
	name: 'FormFieldWrapper',
})
export default class FormFieldWrapper extends Vue {

	/* Props
	============================================*/

	// Hides the label when try
	@Prop({type: Boolean, required: false})
	readonly hideLabel: boolean;

	// The label to display above the field
	@Prop({type: String, required: false})
	readonly label: string;

	// An error message to display
	@Prop({type: String, required: false})
	readonly error: string;

	// Whether or not the wrapped field is required
	@Prop({type: Boolean, required: false})
	readonly required: boolean;

	@Prop({ type: Boolean, default: false })
	readonly readonly: boolean;

}

