















































	import {Component, Prop, Watch} from 'vue-property-decorator';
import Vue from 'vue';

	/**
	 * A clickable Bookmark for a SelectableCard
	 */
	@Component({
		name: 'Bookmark',
	})
	export default class Bookmark extends Vue {

		@Prop({ type: Boolean, default: false })
		readonly initState: boolean;

		/* Data
        ============================================*/
		isActive: Boolean = this.initState;

		/* Computed
        ============================================*/
		get fillColor() : String {
			// $dark-blue
			return this.isActive ? '#2479AA' : 'white';
		}

		get dashSize() : Number {
			return this.isActive ? 0 : 9;
		}

		/* Methods
        ============================================*/
		handleClickEvent() : void {
			this.isActive = !this.isActive;
			this.$emit('bookmarked', this.isActive);
		}

		/* Watcherss
        ============================================*/
		@Watch('initState')
		onWatchChange(newValue, oldValue) {
			if (oldValue !== newValue) {
				this.isActive = this.initState;
			}
		}
	}

