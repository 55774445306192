




























import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import { IEnvironment } from '@/types/Resource';

@Component({
    name: 'EnvironmentPickerModal'
})
export default class EnvironmentPickerModal extends Vue {

    /* Props
    ============================================*/
    @Prop({ type: Array as () => IEnvironment[] })
    selectedEnvironments: IEnvironment[];

    @Prop({ type: Array as () => IEnvironment[] })
    environments: IEnvironment[];

    /* Computed
    ============================================*/
    get alreadySelected(): Boolean {
        return this.selectedEnvironments.some(env => env && env.envId === this.selectedEnvId);
    }

    get selectedEnv(): IEnvironment {
        if (!this.selectedEnvId) return null;
        return this.environments.find(env => env && env.envId === this.selectedEnvId);
    }

    /* Data
    ============================================*/
    selectedEnvId: string = null;

    /* Methods
    ============================================*/

    addEnv() {
        if (!this.selectedEnvId || this.alreadySelected) return;
        this.$emit('add-env', this.selectedEnvId);
    }
}
