








































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Get, Sync } from 'vuex-pathify';
import TaskCard from './TaskCard.vue';
import EaasiTask from '@/models/task/EaasiTask';
import UserPreferenceService from '@/services/UserPreferenceService';
import TaskPreferenceService from '@/services/TaskPreferenceService';
import eventBus from '@/utils/event-bus';

@Component({
	name: 'TaskList',
	components: {
		TaskCard
	}
})
export default class TaskList extends Vue {

	/* Props
	============================================*/
	@Prop({ type: Boolean, default: false })
	readonly collapsible: boolean;

	@Prop({ type: Boolean, default: false })
	readonly closable: boolean;

	@Prop({ type: Boolean, default: false })
	readonly fixed: boolean;

	/* Computed
	============================================*/
	isShow: boolean = false;

	@Get('task/taskQueue')
	taskQueue: EaasiTask[];

	@Get('task/orderedTasks')
	tasks: EaasiTask[];

	@Get('task/completedTasks')
	completedTasks: EaasiTask[];

	get toggleIcon(): String {
		return this.collapsible && this.collapsed ? 'up' : 'down';
	}

	get tasksInProgress(): Number {
		if (!this.tasks.length) return 0;
		return this.tasks.filter(t => !t.isDone).length;
	}

	/* Data
	============================================*/
	private taskPreferenceSvc = new TaskPreferenceService();

	collapsed: boolean = false;
	closed: boolean = false;

	/* Methods
	============================================*/
	async removeTask(task: EaasiTask) {
		await this.$store.dispatch('task/deleteTask', task.id);
	}

	retrieveUserSetting() {
		const userPreference = this.taskPreferenceSvc.retrieveTaskPreferences();
		if (this.closable) {
			this.closed = userPreference?.closed ?? false;
		}
		if (this.collapsible) {
			this.collapsed = userPreference?.collapsed ?? false;
		}
	}

	closeToggle() {
		const closed = !this.closed;
		this.taskPreferenceSvc.persistTaskClosePreference(closed);
		this.closed = closed;
	}

	collapseToggle() {
		const collapsed = !this.collapsed;
		this.taskPreferenceSvc.persistTaskCollapsePreference(collapsed);
		this.collapsed = collapsed;
	}

	showTaskManager() {
		console.log('show task manager');
		this.taskPreferenceSvc.persistTaskClosePreference(false);
		this.taskPreferenceSvc.persistTaskCollapsePreference(false);
		this.closed = false;
		this.collapsed = false;
	}

	hideTaskManager() {
		this.taskPreferenceSvc.persistTaskClosePreference(true);
		this.taskPreferenceSvc.persistTaskCollapsePreference(true);
		this.closed = true;
		this.collapsed = true;
	}

	beforeMount() {
		this.retrieveUserSetting();
		eventBus.$on('task-manager:show', () => this.showTaskManager());
		eventBus.$on('task-manager:hide', () => this.hideTaskManager());
	}

	beforeDestroy() {
		eventBus.$off('task-manager:show');
		eventBus.$off('task-manager:hide');
	}

}
