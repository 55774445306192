
























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

/**
 * A larger UI button with additional areas for text
 * @example ../docs/BigButton.Example.md
 */
@Component({
	name: 'BigButton',
})
export default class BigButton extends Vue {

	/* Props
	============================================*/

	/**
	 * Primary label
	 */
	@Prop({type: String, required: true})
	readonly label: string;

	/**
	 * Make display type: block
	 */
	@Prop({type: Boolean, required: false})
	readonly block: boolean;

	/**
	 * Font Awesome icon name
	 */
	@Prop({type: String, required: false})
	readonly icon: string;

	/**
	 * Text below the primary label
	 */
	@Prop({type: String, required: false})
	readonly subLabel: string;

	/**
	 * Alternative size of the modal. Accepts 'sm, small, lg, or large'
	 */
	@Prop({type: String, required: false, default: 'sm'})
	readonly size: string;

	/**
	 * Additional text that will appear below the button in a colored wrapper
	 */
	@Prop({type: String, required: false})
	readonly info: string

	/**
	 * Additional text that will appear below the button
	 */
	@Prop({type: String, required: false})
	readonly subtext: string

}

