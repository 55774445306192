


























import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import AdminScreen from '../AdminScreen.vue';
import { generateNotificationError, generateNotificationSuccess } from '../../../helpers/NotificationHelper';
import { INotification } from '../../../types/Notification';
import eventBus from '../../../utils/event-bus';

@Component({
	name: 'InstallAndUpdateManagement'
})
export default class InstallAndUpdateManagement extends AdminScreen {

	/* Methods
	============================================*/

	async syncBackend() {
		const res = await this.$store.dispatch('admin/dbDataMigration');
		const notification = res ? generateNotificationSuccess('Successfully synchronized archives') 
			: generateNotificationError('Failed to synchronize archives');
		eventBus.$emit('notification:show', notification);
	}
	
	async migrateDatabase() {
		const res = await this.$store.dispatch('admin/syncEnvironments');
		const notification = res ? generateNotificationSuccess('Successfully migrated old data') 
			: generateNotificationError('Failed to migrate old data');
		eventBus.$emit('notification:show', notification);
	}

}
