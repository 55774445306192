














import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ILabeledEditableItem } from '@/types/ILabeledItem';
import { jsonCopy } from '@/utils/functions';
import eventBus from '@/utils/event-bus';

@Component({
    name: 'EditableTextItem'
})
export default class EditableTextItem extends Vue {

    /* Props
    ============================================*/
    @Prop({ type: Object as () => ILabeledEditableItem })
    item: ILabeledEditableItem;

    @Prop({ type: Boolean })
    readonly: Boolean;

    /* Data
    ============================================*/
    localItem: ILabeledEditableItem;

	refresh() {
		this.localItem = jsonCopy(this.item);
		this.$forceUpdate();
	}

	/* Lifecycle Methods
		============================================*/
	created() {
		this.refresh();
		eventBus.$on('editable-item:refresh', this.refresh);
	}

}
