






















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'PrintJobsModal'
})
export default class PrintJobsModal extends Vue {

    /* Props
    ============================================*/
    @Prop({ type: Array as () => String[] })
    printJobLabels: string[];

    /* Methods
    ============================================*/
    printJob(label: string) {
        return this.$emit('download-print-job', label);
    }

}
