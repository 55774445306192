















import Vue from 'vue';
import { Component } from 'vue-property-decorator';

/**
 * Provides a card for communicating tips to the user
 */
@Component({
	name: 'TipsCard'
})
export default class TipsCard extends Vue {
}
