














import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Checkbox from '@/components/global/forms/Checkbox.vue';

/**
 * A Card for displaying information that can be selected or bookmarked
 * @example ../../docs/SelectableCard.Example.md
 */
@Component({
	name: 'SelectableContainer',
	components: {
        Checkbox,
	}
})
export default class SelectableContainer extends Vue {

	/* Props
	============================================*/
	@Prop({ type: String, required: true })
	readonly title: string;

	@Prop({type: Boolean, default: false })
	readonly disableSelect: boolean;

	@Prop({type: Boolean, default: true})
	readonly isClickable: boolean;

	@Prop({ type: Boolean, default: false })
	readonly isSelected: boolean;

	@Prop({ type: String, default: 'lg' })
	readonly iconSize: string;

	/* Computed
	============================================*/
	get selectStyle() : string {
		return this.isSelected ? 'selected' : '';
	}

	/* Methods
	============================================*/

	toggleSelected(isSelected) : void {
		this.$emit('input', isSelected);
		this.$emit('change', isSelected);
	}

}
