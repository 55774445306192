





























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import EaasiForm from '@/components/global/forms/EaasiForm.vue';
import UiButton from '@/components/global/UiButton.vue';
import Modal from '@/components/global/Modal/Modal.vue';

/**
 * A Modal with a title, subtitle, slot for content, close, and save button
 */
@Component({
	name: 'FormModal',
	components: {
		EaasiForm,
		UiButton,
		Modal
	}
})
export default class FormModal extends Vue {

	$refs!: {
		_form: EaasiForm
	};

	/* Props
    ============================================*/

    /**
     * The user-facing title at the top of the modal
     */
    @Prop({type: String, required: false})
	readonly title: string

    /**
     * Optional subtitle under the main title
     */
    @Prop({type: String, required: false})
    readonly subtitle: string

    /**
     * Save button text
     */
    @Prop({type: String, required: false, default: 'Save'})
    readonly saveText: string

    /**
     * Cancel button text
     */
    @Prop({type: String, required: false, default: 'Cancel'})
	readonly cancelText: string

	@Prop({type: Boolean, required: false, default: false})
	hasRemoveButton: boolean;

    /**
     * Remove button text
     */
    @Prop({type: String, required: false})
	readonly removeText: string

	/**
     * Alternative size of the modal. Accepts 'sm, small, lg, or large'
     */
    @Prop({type: String, default: 'medium'})
    readonly size: string

}

