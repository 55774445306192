








import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

/**
 * A Big Section Heading
 * @example ../docs/BigSectionHeading.Example.md
 */
@Component({
	name: 'BigSectionHeading'
})
export default class BigSectionHeading extends Vue {

/**
 * Title of the Section
 */
@Prop({type: String, required: true})
readonly title: string
}
