






import { Component } from 'vue-property-decorator';
import Vue from 'vue';

/**
 * Provides Wizard to Add Content Resources
 */
@Component({
	name: 'SoftwareResourcesWizard'
})
export default class ContentResourcesWizard extends Vue {
}

