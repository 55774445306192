























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
	name: 'DualToggle',
})
export default class DualToggle extends Vue {

	/* Props
	============================================*/

	/**
	 * The value of the field
	 */
	@Prop({type: String, required: true})
	readonly value: string

	@Prop({type: Array, required: true})
	readonly options: string[]

	/* Methods
	============================================*/

	changeVal() {
		let newOpt = this.value === this.options[0]
			? this.options[1]
			: this.options[0];
		this.$emit('input', newOpt);
	}

	toggle(option: string = null) {
		return option ? this.$emit('input', option) : this.changeVal();
	}

	/* Lifecycle Hooks
	============================================*/

	beforeMount() {
		if (!this.options || this.options.length !== 2) {
			console.error('DualToggle requires an options array with two string values');
		}
	}

}

