





























import Vue from 'vue';
import Component from 'vue-class-component';
import HeaderMenuItem from './HeaderMenuItem.vue';
import HeaderMenuDropDown from '@/components/layout/header/HeaderMenuDropdown.vue';
import { Get, Sync } from 'vuex-pathify';
import { IEaasiUser } from 'eaasi-admin';
import { jsonCopy } from '@/utils/functions';
import ResourceSearchQuery from '../../../models/search/ResourceSearchQuery';
import { ROUTES } from '@/router/routes.const';

@Component({
	name: 'AppHeader',
	components: {
		HeaderMenuDropDown,
		HeaderMenuItem
	}
})
export default class AppHeader extends Vue {

	/* Data
	============================================*/
	searchBorderColor = '#C7E4F5';
	manageNodeRoute = ROUTES.MANAGE_NODE.ROOT;

	/* Computed
	============================================*/

	@Get('nodeName')
	nodeName: string;

	@Get('loggedInUser')
	user: IEaasiUser;

	@Sync('resource/query@keyword')
	searchKeyword: string;

	/* Methods
	============================================*/

	clearSearchQuery() {
		this.searchKeyword = '';
		this.search();
	}

	async search() {
		const keyword = this.searchKeyword;
		if (this.$route.path === ROUTES.RESOURCES.EXPLORE) {
			await this.$store.dispatch('resource/searchResources');
		} else {
			const query = new ResourceSearchQuery();
			this.$store.commit('resource/SET_QUERY', {...query, keyword});
			this.$router.push({ name: 'Explore Resources', params: { keyword } });
		}
	}
};
