





















import Vue from 'vue';;
import { Component, Prop } from 'vue-property-decorator';
import { IMenuItem } from 'eaasi-nav';
import EaasiIcon from '@/components/global/icons/EaasiIcon.vue';
@Component({
	components: { EaasiIcon }
})
export default class LeftMenuItem extends Vue {
	@Prop({type: Object as () => IMenuItem, required: true})
	readonly item: IMenuItem
}
