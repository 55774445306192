
























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Modal from '@/components/global/Modal/Modal.vue';
import UiButton from '@/components/global/UiButton.vue';

@Component({
	name: 'InfoModal',
	components: {
		Modal,
		UiButton
	}
})
export default class InfoModal extends Vue {

	/* Props
	============================================*/

	/**
     * Cancel button text
     */
    @Prop({type: String, required: false, default: 'Okay'})
	readonly buttonText: string

    /**
     * The user-facing title at the top of the modal
     */
    @Prop({type: String, required: true})
    readonly title: string

	 /**
     * Alternative size of the modal. Accepts 'sm, small, lg, or large'
     */
    @Prop({type: String, default: 'medium'})
    readonly size: string

}

