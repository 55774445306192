


























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IDrive, IEditableDrive } from '@/types/Resource';
import DriveEditModal from './DriveEditModal.vue';
import { generateId, jsonCopy } from '@/utils/functions';

@Component({
	name: 'ConfiguredDrives',
	components: { DriveEditModal }
})
export default class ConfiguredDrives extends Vue {

    /* Props
    ============================================*/
	@Prop({ type: Array as () => IDrive[], required: true })
	drives: IDrive[]

    @Prop({ type: Boolean })
    readonly: Boolean;

    /* Computed
	============================================*/
	get drivesWithIds(): IEditableDrive[] {
		return this.drives.map(d => {
			return {...d, uid: generateId()};
		});
	}

    /* Data
    ============================================*/
	activeDrive: IEditableDrive = null;

    /* Methods
    ============================================*/
    remove(drive: IEditableDrive) {
		const updatedDrives = this.drivesWithIds.filter(d => d.uid != drive.uid);
		this.$emit('update-drives', updatedDrives);
    }

    add() {
		this.activeDrive = {
			uid: generateId(),
			data: '',
			iface: '',
			filesystem: '',
			bus: '',
			unit: '',
			type: 'floppy',
			boot: false,
			plugged: false,
		} as IEditableDrive;
	}

	save(drive: IEditableDrive) {
		let updatedDrives: IEditableDrive[] = this.drivesWithIds;
		this.drivesWithIds.some(d => d.uid === drive.uid)
			? updatedDrives = this.drivesWithIds.map(d => d.uid === drive.uid ? drive : d)
			: updatedDrives.push(drive);

		this.$emit('update-drives', updatedDrives);
		this.closeModal();
	}

	closeModal() {
		this.activeDrive = null;
	}

	edit(drive: IEditableDrive) {
		this.activeDrive = jsonCopy(drive);
	}

}
