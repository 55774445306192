



















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IRadioOption } from '@/types/Forms';

/**
 * A component to allow selection between two values, with a description
 * @example ../../docs/DescriptiveRadios.Example.md
 */
@Component({
	name: 'DescriptiveRadios',
})
export default class DescriptiveRadios extends Vue {

	/* Props
	============================================*/

	/**
	 * A list of descriptive radio button options
	 */
	@Prop({type: Array, required: true})
	readonly options: IRadioOption[]

	/**
	 * The selected option value for use with v-model directive
	 */
	@Prop({type: Number, required: false})
	readonly value: number;

	/**
	 * Option to match height of descriptive radios
	 */
	@Prop({ type: Boolean, default: false })
	readonly matchHeight: boolean;

	/* Computed
	============================================*/

	get colSize() {
		if(this.options.length <= 3) return 12 / this.options.length;
		return 4;
	}

	/* Methods
	============================================*/

	isChecked(val) {
		return val === this.value;
	}

}

