


































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Get } from 'vuex-pathify';
import { IEaasiUser } from 'eaasi-admin';
import config from '../../../config';
import ChangePasswordModal from './ChangePasswordModal.vue';
import NodePreferencesModal from './NodePreferencesModal.vue';

@Component({
	name: 'HeaderMenuDropDown',
	components: {
		ChangePasswordModal,
		NodePreferencesModal
	}
})
export default class HeaderMenuDropdown extends Vue {

	/* Props
	============================================*/

	/**
	 * The text to display in the trigger element
	 */
	@Prop({type: String, required: true})
	readonly label: string;

	/**
	 * The icon to display in the trigger element
	 */
	@Prop({type: String, required: false})
	readonly icon: string;

	/* Computed
	============================================*/

	@Get('loggedInUser')
	user: IEaasiUser;

	/* Data
	============================================*/

	isDropDownVisible: boolean = false;
	isChangePasswordModalVisible: boolean = false;
	allowChangePassword: boolean = !config.SAML_ENABLED;
	isNodePreferencesModalVisible: boolean = false;

	/* Methods
	============================================*/

	async logOut() {
		await this.$store.dispatch('logout');
	}

	changePassword() {
		window.location.href = (window as any).keycloak.createAccountUrl() + '#/security/signingin';
	}
}

