
























import { Component } from 'vue-property-decorator';
import BaseFormField from './BaseFormField.vue';
import FormFieldWrapper from './FormFieldWrapper.vue';

/**
 * A Text Input form field
 * @example ../../docs/TextInput.Example.md
 */
@Component({
	name: 'TextInput',
	components: {
		FormFieldWrapper
	}
})
export default class TextInput extends BaseFormField {}

