





















import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { IContentFile } from '../../../../types/Resource';

@Component({
    name: 'MediaFilesList'
})
export default class MediaFilesList extends Vue {

    /* Props
    ============================================*/
    @Prop({ type: Array as () => IContentFile[] })
    files: IContentFile[];

    /* Computed
    ============================================*/

    /* Data
    ============================================*/

    /* Methods
    ============================================*/

    /* Lifecycle Hooks
    ============================================*/

}
