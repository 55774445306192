


















import AdminScreen from '@/components/admin/AdminScreen.vue';
import { Component } from 'vue-property-decorator';
import TaskList from './TaskList.vue';

@Component({
	name: 'RunningTaskManagement',
	components: {
		TaskList
	}
})
export default class RunningTaskManagement extends AdminScreen {}

