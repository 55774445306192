


































































































import Vue from 'vue';
import InfoModal from '@/components/global/Modal/InfoModal.vue';
import { Component } from 'vue-property-decorator';
import { IApplicationLog } from '@/types/ApplicationLog';
import { Get, Sync } from 'vuex-pathify';
import config from '../../../config';
import zlib from 'zlib';

/**
 * A pop-up modal that notifies a user that an error has occurred
 */
@Component({
	name: 'ErrorModal',
	components: { InfoModal }
})
export default class ErrorModal extends Vue {

	/* Computed
	============================================*/

	get size() {
		return this.showDebugErrors ? 'md' : 'sm';
	}

	@Sync('appError')
	error: string;

	@Get('showDebugErrors')
	showDebugErrors: boolean;

	/* Data
	============================================*/
	apiEvents: IApplicationLog[] = [];
	appVersion: string = '';
	backendLogsUrl: string = config.EMIL_SERVICE_ENDPOINT + '/error-report';

	/* Methods
	============================================*/

	closeModal() {
		this.error = null;
	}

	async getApiEvents() {
		this.apiEvents = await this.$store.dispatch('admin/getMostRecentErrorLogs');
		this.appVersion = config.APP_VERSION;
	}

	downloadFrontendLogs() {
		let apiEventsString = this.apiEvents.map(event => this.objToString(event));
		let logs = `
##### Front-End Stack Trace #####
${this.objToString(this.error)}
###### Web-Api Event List #######
${apiEventsString}
###### App Version #######
EaaSi Version: ${this.appVersion}
########## < END > ##############`;

		var output = zlib.gzipSync(logs);
		var blob = new Blob([output.buffer], { type: 'application/gzip' });
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.setAttribute('download', 'eaasi-frontend-error-report.gz');
		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
	}

	objToString (obj) {
		var str = '';
		for (var p in obj) {
			if (obj.hasOwnProperty(p)) {
				str += p + '::' + obj[p] + '\n';
			}
		}
		return str;
	}

	executeCopy(text: string) {
		var input = document.createElement('textarea');
		document.body.appendChild(input);
		input.value = text;
		input.focus();
		input.select();
		document.execCommand('Copy');
		input.remove();
	}

	/* Lifecycle hooks
	============================================*/
	async beforeMount() {
		await this.getApiEvents();
	}

	beforeDestroy() {
		this.error = null;
	}
}

