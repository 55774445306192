


















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ILabeledEditableItem } from '@/types/ILabeledItem';
import { jsonCopy } from '@/utils/functions';
import eventBus from '@/utils/event-bus';

@Component({
    name: 'EditableSelectList'
})
export default class EditableSelectList extends Vue {

    /* Props
    ============================================*/
    @Prop({ type: Object as () => ILabeledEditableItem })
    item: ILabeledEditableItem;

    @Prop({ type: Boolean })
    readonly: Boolean;

    @Prop({ type: String, required: true })
    anchor: string;

    @Prop({ type: String, required: true })
    label: string;

    @Prop({ type: String })
    defaultSelected: string;

    /* Computed
    ============================================*/
    get selectedValue() {
        const dataItem = this.item.data.find(d => d.value === this.item.value);
        return dataItem ? dataItem[this.label] : this.defaultSelected;
    }

    /* Data
    ============================================*/
	localItem: ILabeledEditableItem;

	refresh() {
		this.localItem = jsonCopy(this.item);
		this.$forceUpdate();
	}

	/* Lifecycle Methods
    ============================================*/
	created() {
		this.refresh();
		eventBus.$on('editable-item:refresh', this.refresh);
	}

}
