

















import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { INotification } from '@/types/Notification';
import eventBus from '../../utils/event-bus';

@Component({
    name: 'Notifications'
})
export default class Notifications extends Vue {

    /* Data
    ============================================*/
    notifications: INotification[] = [];

    /* Methods
    ============================================*/
    showNotification(notification: INotification) {
        this.notifications.push(notification);
        setTimeout(() => {
            this.notifications = this.notifications.filter(n => notification.id != n.id);
        }, notification.time);
}

    closeNotification(notification: INotification) {
        this.notifications = this.notifications.filter(n => n.id !== notification.id);
    }

    /* Lifecycle Hooks
    ============================================*/
    beforeMount() {
        eventBus.$on('notification:show',
            (notification: INotification) => this.showNotification(notification)
        );
    }

    beforeDestroy() {
        eventBus.$off('notification:show');
    }

}
