






































import { IResourceSearchQuery } from '@/types/Search';
import {isSpaces} from '@/utils/functions';
import { Component, Prop } from 'vue-property-decorator';
import {Get, Sync} from 'vuex-pathify';
import BaseFormField from './BaseFormField.vue';
import FormFieldWrapper from './FormFieldWrapper.vue';

/**
 * A text-input styled specifically for search keyword input
 * @example ../../docs/SearchBar.Example.md
 */
@Component({
	name: 'SearchBar',
	components: {
		FormFieldWrapper
	}
})
export default class SearchBar extends BaseFormField {

	/* Props
	============================================*/

	/**
	 * The color of the input border
	 */
	@Prop({type: String, required: false, default: '#1C5F6B'})
	readonly borderColor: string

	/* Computed
	============================================*/

	@Get('resource/query@keyword')
	queryKeyword: string;

	@Get('resource/lastSearchKeyword')
	lastSearchKeyword: string;

	get showSearchChevron() {
		if (!this.queryKeyword || isSpaces(this.queryKeyword)) {
			return true;
		}
		if (this.queryKeyword as string === '' && this.lastSearchKeyword === '') {
			return true;
		}
		return this.queryKeyword as string !== this.lastSearchKeyword || this.queryKeyword as string === '';
	}

}

