










































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IEmulator, IEmulatorEntry } from 'eaasi-admin';
import EmulatorImportRequest from '@/models/admin/EmulatorImportRequest';
import EaasiTask from '@/models/task/EaasiTask';
import { ITaskState } from '../../../types/Task';

@Component({
	name: 'EmulatorDetailsModal'
})
export default class EmulatorModal extends Vue {

	/* Props
	============================================*/

	@Prop({type: Object as () => IEmulator, required: true})
	readonly emulator: IEmulator;

	get title() {
		return `${this.emulator.name.toUpperCase()} Images`;
	}

	get hasMultipleImages() {
		return this.emulator.entries.length > 1;
	}

	/* Methods
	============================================*/

	canUpdate(e: IEmulatorEntry) {
		if(this.isLatest(e)) return false;
		let otherVersions = this.emulator.entries
			.filter(x => e.provenance.url === x.provenance.url);
		return !otherVersions.find(x => this.isLatest(x));
	}

	/**
	 * Determines if an emulator entry is the latest version
	 */
	isLatest(e: IEmulatorEntry) {
		return e.provenance.tag === 'latest';
	}

	/**
	 * Determines if an emulator entry is the default version
	 */
	isDefault(e: IEmulatorEntry) {
		return !!e.tags?.includes('default');
	}

	async makeDefault(entry: IEmulatorEntry) {
		// Assume this will succeed and update the checkbox immediately
		let previousDefault = this.setDefaultTagToEntry(entry.id);
		let success = await this.$store.dispatch('admin/setDefaultEmulatorVersion', entry.id);
		if(success) {
			// Refresh the latest emulator list
			this.$store.dispatch('admin/getEmulators');
		} else {
			// If first call failed, revert to previous version
			this.setDefaultTagToEntry(previousDefault);
		}
	}

	setDefaultTagToEntry(id) {
		let previousDefault;
		this.emulator.entries = this.emulator.entries.map(e => {
			let newTags = e.tags ? [...e.tags] : [];
			if (this.isDefault(e)) {
				previousDefault = e.id;
				newTags = newTags.filter(tag => tag !== 'default');
			}
			if (e.id === id) {
				newTags.push('default');
			}
			return {
				...e,
				tags: newTags
			};
		});
		return id;
	}

	async updateImage(entry: IEmulatorEntry) {
		let request = new EmulatorImportRequest();
		request.urlString = entry.provenance.url;
		request.tag = entry.provenance.tag;
		request.update = true;
		const task = await this.$store.dispatch('admin/importEmulator', request) as EaasiTask;
		if(!task) return;
		const taskWithDescription: ITaskState = {
			...task,
			description: `Import Emulator: ${entry.provenance.url}:${entry.provenance.tag}`
		};
		await this.$store.dispatch('task/addTaskToQueue', taskWithDescription);
		this.$store.commit('SET_ACTIVE_TASK', task);
		this.$emit('close');
	}

}

