









































import { uniqueId } from 'lodash';
import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseFormField from './BaseFormField.vue';
import FormFieldWrapper from './FormFieldWrapper.vue';

/**
 * A search select list form input
 * @example ../../docs/SearchSelectList.Example.md
 */
@Component({
	name: 'SearchSelectList',
	components: {
		FormFieldWrapper
	}
})
export default class SearchSelectList extends BaseFormField {

	/* Props
	============================================*/
	@Prop({ type: String, required: true })
	readonly optionLabel: string;

	@Prop({ type: String, required: true })
	readonly anchor: string;

	@Prop({ type: String })
	readonly placeholder: string;

	@Prop({ type: Array, required: true })
	readonly data: any[];

	/* Computed
	============================================*/
	get filteredData() {
		if (!this.query) return this.data;
		return this.data.filter(d => d[this.optionLabel].trim().toLowerCase().indexOf(this.query.trim().toLowerCase()) >= 0);
	}

	/* Data
	============================================*/
	query: string = null;
	showList: boolean = false;

	onQueryChange(e) {
		const query = e.target.value;
		this.query = query;
		if (query.length > 0) {
			this.showList = true;
		} else {
			this.clearQuery();
		}
	}

	selectItem(item) {
		this.query = item[this.optionLabel];
		this.$emit('input', item[this.anchor]);
		this.closeList();
	}

	openList() {
		this.showList = true;
	}

	toggleList() {
		this.showList ? this.closeList() : this.openList();
	}

	closeList() {
		this.showList = false;
	}

	clearQuery() {
		this.query = null;
		this.$emit('input', null);
	}

	generateKey(prefix: string): string {
		return uniqueId(prefix);
	}

	@Watch('value')
	onValueChange(nextVal, prevVal) {
		if (nextVal === prevVal) return;
		if (!nextVal) this.clearQuery();
		const newItem = this.data.find(item => item[this.anchor] === nextVal);
		if (newItem) {
			this.selectItem(newItem);
		}
	}
	
}

