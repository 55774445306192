







import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ILabeledItem } from '@/types/ILabeledItem';

/**
 * A labeled item
*/
@Component({
	name: 'LabeledItem.vue'
})
export default class LabeledItem extends Vue {
	/**
	 * Labeled item display data
	 */
	@Prop({type: Object as () => ILabeledItem, required: true})
	readonly labeledItem : ILabeledItem
}
