





























































































import PermissionResolver from '@/services/Permissions/PermissionResolver';
import {IEaasiUser} from 'eaasi-admin';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import {Get, Sync} from 'vuex-pathify';
import { ImportType } from '@/types/Import';

@Component({
	name: 'ImportSelect'
})
export default class ImportSelect extends Vue {

	/* Computed
	============================================*/

	@Sync('import/importStep')
	step: number;

	@Sync('import/importType')
	importType: ImportType;

	@Get('loggedInUser')
	user: IEaasiUser;

	@Get('permissions')
	permit: PermissionResolver;

	get userCanImportEnvironment() {
		if (!this.user) return false;
		return this.permit.allowsSingleEnvironmentImport();
	}

	/* Methods
	============================================*/

	chooseImportType(chosenImportType: ImportType) {
		this.importType = chosenImportType;
		this.step++;
	}

	/* Watchers
	============================================*/

	/**
	 * Reset import metadata / files if type is changed by user
	 */
	@Watch('importType')
	onTypeChange(newType) {
		if(!newType) return;
		this.$store.commit('import/INIT_FOR_TYPE');
	}

}

