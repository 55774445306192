




















import { Component, Prop } from 'vue-property-decorator';
import BaseFormField from './BaseFormField.vue';
import FormFieldWrapper from './FormFieldWrapper.vue';

/**
 * A text area input form field
 * @example ../../docs/TextAreaInput.Example.md
 */
@Component({
	name: 'TextAreaInput',
	components: {
		FormFieldWrapper
	}
})
export default class TextAreaInput extends BaseFormField {

	// The number of rows to display
	@Prop({type: [Number, String], default: 4})
	readonly rows: number | string

}

