


















































































import { Component } from 'vue-property-decorator';
import Vue from 'vue';

	/**
	 * A small clickable Bookmark component
	 * @example ../docs/SmallBookmark.Example.md
	 */
	@Component({
		name: 'SmallBookmark',
	})
export default class SmallBookmark extends Vue {

		/* Data
        ============================================*/
		isActive: Boolean = false;

		/* Computed
        ============================================*/
		get fillColor() : String {
			// $dark-blue
			return this.isActive ? '#2479AA' : 'white';
		}

		get dashSize() : Number {
			return this.isActive ? 0 : 12;
		}

		get actionMessage() {
			return this.isActive ? 'Remove Bookmark' : 'Add Bookmark';
		}

		/* Methods
        ============================================*/
		handleClickEvent() : void {
			this.isActive = !this.isActive;
			this.$emit('bookmarked', this.isActive);
		}
}
