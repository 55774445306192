







import Vue from 'vue';
import Component from 'vue-class-component';

/**
 * An Eaasi-themed loading component with an animated spinner
 * @example ../docs/Loader.Example.md
 */
@Component({
	name: 'Loader'
})
export default class Loader extends Vue {}
