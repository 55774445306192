



































































































































import ConfirmModal from '@/components/global/Modal/ConfirmModal.vue';
import ResourceFacets from '../search/ResourceFacets.vue';
import AppliedSearchFacets from '../search/AppliedSearchFacets.vue';
import ResourceList from '../ResourceList.vue';
import { IEaasiResource } from '@/types/Resource.d.ts';
import { Get, Sync } from 'vuex-pathify';
import { IEaasiUser } from 'eaasi-admin';
import { IResourceSearchResponse, IResourceSearchFacet, IResourceSearchQuery } from '@/types/Search';
import SlideMenuControlButtons from '@/components/resources/SlideMenuControlButtons.vue';

import { IBookmark } from '@/types/Bookmark';
import Vue from 'vue';
import { Component, Watch, Prop } from 'vue-property-decorator';
import { ROUTES } from '@/router/routes.const';
import { IEaasiTab } from 'eaasi-nav';
import SearchQueryService, { QuerySource } from '@/services/SearchQueryService';

@Component({
    name: 'MyBookmarksScreen',
    components: {
		AppliedSearchFacets,
		ResourceFacets,
		ResourceList,
		ConfirmModal,
		SlideMenuControlButtons
	}
})
export default class MyBookmarksScreen extends Vue {

    /* Props
	============================================*/
	@Prop({ type: Array as () => IEaasiTab[], required: true })
	readonly actionMenuTabs: IEaasiTab[];

    /* Computed
    ============================================*/

    @Sync('resource/selectedResources')
    selectedResources: IEaasiResource[];

    @Sync('resource/query')
    query: IResourceSearchQuery;

    @Get('resource/result')
    bentoResult: IResourceSearchResponse;

    @Sync('resource/query@selectedFacets')
    selectedFacets: IResourceSearchFacet[];

    @Get('loggedInUser')
    user: IEaasiUser;

	@Get('resource/facetsOfSingleTypeSelected')
	facetsOfSingleTypeSelected: Boolean;

	@Get('resource/onlySelectedFacets')
	onlySelectedFacets: IResourceSearchFacet[];

	@Get('resource/bookmarks')
	bookmarks: IBookmark[];

	get hasSelectedFacets() {
		return this.onlySelectedFacets.length > 0;
	}

	get totalResults() {
		if (!this.onlySelectedFacets)  {
			return;
		}
		const totalResultsArr = this.onlySelectedFacets.flatMap(f => f.values.map(v => v.total));
		return Math.min.apply(null, totalResultsArr);
	}

	get hasResults() {
		if (!this.bentoResult) return false;
		return this.bentoResult.software.result.length > 0
			|| this.bentoResult.content.result.length > 0
			|| this.bentoResult.images.result.length > 0
			|| this.bentoResult.environments.result.length > 0;
	}

	/* Data
	============================================*/
	private readonly queryService = new SearchQueryService(QuerySource.MyBookmarks);
	readonly exploreResourcesPath = ROUTES.RESOURCES.EXPLORE;

	isClearBookmarksModalVisible: boolean = false;

    /* Methods
    ============================================*/

    async search() {
		// wait for facets update it's selected property on this tick, call search on next tick
		this.$nextTick(async () => {
			this.query = {
				...this.query,
				userId: this.user.id,
				onlyBookmarks: true,
				onlyImportedResources: false,
				archives: []
			};
			await this.$store.dispatch('resource/searchResources');

			if (this.bentoResult) {
				this.$store.commit('bookmark/SET_BOOKMARKS', this.bentoResult.bookmarks);
			}
		});
	}

	async paginate(page) {
		this.query.page = page;
		await this.$store.dispatch('resource/searchResources');
	}

    async getAll(types) {
		this.$store.commit('resource/UNSELECT_ALL_FACETS');
		this.$store.commit('resource/SET_SELECTED_FACET_RESOURCE_TYPE', types);
		await this.search();
	}

	raiseClearBookmarksModal() {
    	this.isClearBookmarksModalVisible = true;
	}

	closeClearBookmarksModal() {
    	this.isClearBookmarksModalVisible = false;
	}

    async clearBookmarks() {
        await this.$store.dispatch('bookmark/clearBookmarks', this.user.id);
        this.isClearBookmarksModalVisible = false;
	}

	openActionMenu(tab: IEaasiTab) {
		this.$emit('open-action-menu', tab);
	}

	init() {
		const { retrieveQuery } = this.$route.query;
		if (retrieveQuery) {
			const query: IResourceSearchQuery = this.queryService.retrieveQuery();
			if (query) {
				this.query = query;
			}
		}
	}

    /* Lifecycle Hooks
    ============================================*/

	beforeDestroy() {
		this.queryService.persistQuery(this.query);
		this.selectedResources = [];
		this.$store.dispatch('resource/clearSearchQuery');
		this.$store.commit('resource/SET_RESULT', null);
	}

	beforeMount() {
		this.init();
		this.search();
	}

	@Watch('hasSelectedFacets')
	async onSelectedFacets(curVal, prevVal) {
		if (!curVal && prevVal === undefined) {
			return;
		}
		// if we're unselecting the last facet, do a clear search
		if (prevVal && !curVal && this.query.selectedFacets.length > 0) {
			await this.search();
			// per https://gitlab.com/eaasi/program_docs/eaasi/-/issues/948
			// if we clear the search here, we effectively get back all resources.
			// since this component deals specifically with Bookmarks,
			// we should simply re-run search when last facet is cleared.
			// await this.$store.dispatch('resource/clearSearch');
		}
	}

}
