











































	import AdminScreen from '../AdminScreen.vue';
	import { Component } from 'vue-property-decorator';
	import EmulatorList from './EmulatorList.vue';
	import EmulatorDetailsModal from './EmulatorDetailsModal.vue';
	import EmulatorImportModal from './EmulatorImportModal.vue';
	import { Get, Sync } from 'vuex-pathify';
	import { IEmulator } from 'eaasi-admin';
	const emulators = require('../../../assets/json/emulators.json');

	@Component({
		name: 'EmulatorManagement',
		components: {
			EmulatorList,
			EmulatorDetailsModal,
			EmulatorImportModal
		}
	})
	export default class EmulatorManagement extends AdminScreen {

		/* Computed
        ============================================*/

		@Sync('admin/activeEmulator')
		activeEmulator: IEmulator;

		@Get('admin/emulators')
		list: IEmulator[];

		get result() {
			if (!this.list || !this.list.length) return [];
			return this.list.filter(x => x.name.indexOf(this.keyword) > -1);
		}

		/* Data
        ============================================*/

		keyword: string = '';
		showImportModal: boolean = false;


		/* Methods
        ============================================*/

		addEmulator() {
			this.showImportModal = true;
		}

		search(keyword) {
			this.keyword = keyword;
		}

		showDetails(emulator) {
			this.activeEmulator = emulator;
		}

		/* Lifecycle Hooks
        ============================================*/

		mounted() {
			this.$store.dispatch('admin/getEmulators', emulators);
		}

	}

