


















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import EaasiTask from '@/models/task/EaasiTask';

@Component({
    name: 'TaskCard'
})
export default class TaskCard extends Vue {

    /* Props
    ============================================*/
    @Prop({ type: Object, required: true })
    task: EaasiTask;

    /* Computed
	============================================*/
	get hasError(): boolean {
		return this.task.status !== '0';
	}

    get descriptionOrDefault(): string {
        return this.task.description ? this.task.description : `Task #${this.task.taskId}`;
    }

}
