var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pagination-wrapper"},[_c('div',{staticClass:"flex-row justify-between"},[_c('div',[_c('label',[_vm._v(_vm._s(_vm.totalText))])]),(_vm.numPages > 1)?_c('ul',{staticClass:"pagination"},[(_vm.numPages > _vm.maxPages)?_c('li',{class:[
					'page-arrow',
					{
						'hide-page': !_vm.showGoToStart,
						'disabled-arrow': _vm.isFirstPage
					}
				],on:{"click":function($event){return _vm.paginate(1)}}},[_c('a',{attrs:{"href":"javascript:void(0);"}},[_vm._v("❮❮")])]):_vm._e(),_c('li',{class:[
					'page-arrow',
					{
						'hide-page': _vm.currentPage <= 1,
						'disabled-arrow': _vm.isFirstPage
					}
				],on:{"click":function($event){return _vm.paginate(_vm.currentPage - 1)}}},[_c('a',{attrs:{"href":"javascript:void(0);"}},[_vm._v("❮")])]),_vm._l((_vm.pages),function(p){return _c('li',{key:p,class:{'active': p === _vm.currentPage},on:{"click":function($event){return _vm.paginate(p)}}},[_c('a',{class:{'active': p === _vm.currentPage},attrs:{"href":"javascript:void(0);"}},[_vm._v(_vm._s(p))])])}),_c('li',{class:[
					'page-arrow',
					{
						'hide-page': _vm.currentPage >= _vm.numPages,
						'disabled-arrow': _vm.isLastPage
					}
				],on:{"click":function($event){return _vm.paginate(_vm.currentPage + 1)}}},[_c('a',{attrs:{"href":"javascript:void(0);"}},[_vm._v("❯")])]),(_vm.numPages > _vm.maxPages)?_c('li',{class:[
					'page-arrow',
					{
						'hide-page': !_vm.showGoToEnd,
						'disabled-arrow': _vm.isLastPage
					}
				],on:{"click":function($event){return _vm.paginate(_vm.numPages)}}},[_c('a',{attrs:{"href":"javascript:void(0);"}},[_vm._v("❯❯")])]):_vm._e()],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }