


































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { IChangePasswordRequest } from '@/types/Auth';
import Alert from '@/components/global/Alert/Alert.vue';
import { EaasiForm } from '@/components/global';
import AuthService from '@/services/AuthService';
import { generateNotificationSuccess } from '@/helpers/NotificationHelper';
import eventBus from '@/utils/event-bus';

@Component({
	name: 'ChangePasswordModal',
	components: {
		Alert
	}
})
export default class ChangePasswordModal extends Vue {

	$refs!: {
		_form: EaasiForm
	};

	/* Props
	============================================*/

	/* Data
	============================================*/

	error: string = null;
	request: IChangePasswordRequest = {
		password: '',
		newPassword: '',
		newPasswordConfirm: '',
	}

	/* Computed
	============================================*/

	/* Methods
	============================================*/

	confirmRules(value: string): string | boolean {
		if(!value || this.request.newPassword !== value) {
			return 'Does not match new password';
		}
		return null;
	}

	async changePassword() {
		this.error = null;
		let res = await AuthService.changePassword(this.request);
		if (!res) {
			this.error = 'An error occurred. Please double-check your current password and try again.';
			return;
		}
		eventBus.$emit('notification:show', generateNotificationSuccess('Password updated successfully.'));
		this.$emit('close');
	}

	/* Lifecycle Hooks
	============================================*/

	/* Watchers
	============================================*/

}

