

























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ILabeledItem } from '@/types/ILabeledItem';
import EditableTextItem from './EditableTextItem.vue';
import EditableCheckboxItem from './EditableCheckboxItem.vue';
import EditableSelectList from './EditableSelectList.vue';

@Component({
    name: 'EditableLabeledItemList',
    components: {
		EditableTextItem,
		EditableSelectList,
        EditableCheckboxItem
    }
})
export default class EditableLabeledItemList extends Vue {

    /* Props
    ============================================*/
	@Prop({type: Array as () => ILabeledItem[], required: true })
	labeledItems: ILabeledItem[]
    
    @Prop({ type: Boolean })
    readonly: Boolean;

}
