

















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IResourceSearchFacet, IResourceSearchFacetAttribute, IResourceSearchFacetValue } from '@/types/Search.d.ts';
import Checkbox from '@/components/global/forms/Checkbox.vue';
import Modal from '@/components/global/Modal/Modal.vue';
import UiButton from '@/components/global/UiButton.vue';

@Component({
	name: 'SearchFacetModal',
	components: {
		Checkbox,
		Modal,
		UiButton,
	}
})
export default class SearchFacetModal extends Vue {

    /* Props
    ============================================*/
    @Prop({type: Object as () => IResourceSearchFacet, required: true})
    readonly facet: IResourceSearchFacet;

    /* Data
    ============================================*/
    attributes: IResourceSearchFacetAttribute[] = [];
    activeAttribute: IResourceSearchFacetAttribute = null;

    /* Methods
    ============================================*/
    filterFacetValues(attribute) {
    	if (attribute.facetValues.length < 1) return;
    	this.activeAttribute = attribute;
    }

    initAttributes() {
    	const attributeLabels: string[] = 'abcdefghijklmnopqrstuvwxyz'.split('');
    	const defaultAttribute: IResourceSearchFacetAttribute = {
    		label: '#',
    		facetValues: this.facet.values
    	};
    	const attributes: IResourceSearchFacetAttribute[] = attributeLabels.map(attLabel => {
    		let facetValues: IResourceSearchFacetValue[] = [];
    		this.facet.values.forEach(facetValue => {
    			if (facetValue.label.charAt(0).toLowerCase() === attLabel) {
    				facetValues.push(facetValue);
    			}
    		});
    		return { label: attLabel, facetValues };
    	});
    	attributes.unshift(defaultAttribute);
    	this.activeAttribute = defaultAttribute;
    	this.attributes = attributes;
    }

    /* Lifecycle Hooks
    ============================================*/
    beforeMount() {
    	this.initAttributes();
    }

}
