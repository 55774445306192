




































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

/**
 * Pagination for paginated lists of data
 * @example ../docs/Pagination.Example.md
 */
@Component({
	name: 'Pagination',
})
export default class Pagination extends Vue {

	/* Props
	============================================*/

	// The total results to divide into pages
	@Prop({type: Number, required: true})
	readonly totalResults: number;

	// The amount of results to show per page
	@Prop({type: Number, default: 25})
	readonly resultsPerPage: number;

	// How many page numbers to show at a time
	@Prop({type: Number, default: 4})
	readonly maxPages: number;

	// The currently selected page number
	@Prop({type: Number})
	readonly pageNum: number;

	/* Data
	============================================*/

	currentPage: number = 1;
	pages: number[] = [];
	numPages: number = 1;
	showGoToStart: boolean = false;
	showGoToEnd: boolean = false;

	/* Computed
	============================================*/

	get isFirstPage(): boolean {
		return this.currentPage === 1;
	}

	get isLastPage(): boolean {
		return this.currentPage === Math.ceil(this.totalResults / this.resultsPerPage);
	}

	get totalText(): string {
		let total = this.totalResults;
		if(total <= 0) return 'Records 0 of 0';
		let start = this.currentPage > 1
			? (this.currentPage - 1) * this.resultsPerPage
			: 1;
		let end = this.currentPage * this.resultsPerPage;
		end = end > total ? total : end;
		return `Records ${start}-${end} of ${total}`;
	}

	/* Methods
	============================================*/

	updateResult(): void {

		// Get pagination data
		let numPages = Math.ceil(this.totalResults / this.resultsPerPage);
		let start = 1, end = numPages;
		let pages = [];
		let max = this.maxPages;
		let middle = Math.floor(max / 2);

		// Limit to show only X page numbers at a time.
		if(numPages > max) {
			// Active page should be third number, unless it is within 2 pages of
			// the first or last page, then adjust accordingly
			start = this.currentPage - middle;
			end = this.currentPage + (max - middle - 1);
			if(start < 1) start = 1;
			if (end <= max) end = (max > numPages ? numPages : max);
			if (end > numPages) end = numPages;
			if(start > end - (max - 1)) start = end - (max - 1);
		}

		// Add the page numbers to an array for v-for loop
		for(let i = start; i <= end; i++) {
			pages.push(i);
		}

		this.pages = pages;
		this.numPages = numPages;
		this.showGoToStart = start > 1;
		this.showGoToEnd = end < numPages;
	}

	paginate(pageNum: number, suppressEvent: boolean = false) {
		if(pageNum === this.currentPage) return;
		if(pageNum < 1 || pageNum > this.numPages) return;
		this.currentPage = pageNum;
		if(!suppressEvent) {
			this.$emit('paginate', pageNum);
		}
		this.updateResult();
	}

	/* Lifecycle Hooks
	============================================*/

	mounted() {
		this.updateResult();
	}

	/* Watchers
	============================================*/

	@Watch('pageNum')
	onPageNumChanged(newPage: number) {
		if(newPage !== this.currentPage) {
			this.paginate(newPage, true);
		}
	}

	@Watch('totalResults')
	onTotalResultsChanged() {
		this.updateResult();
	}

	@Watch('resultsPerPage')
	onResultsPerPage() {
		this.updateResult();
	}

}

