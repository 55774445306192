













































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Sync } from 'vuex-pathify';
import ContentImportResource from '@/models/import/ContentImportResource';

@Component({
	name: 'ContentImportMetadata',
})
export default class ContentImportMetadata extends Vue {

	/* Computed
	============================================*/

	@Sync('import/importStep')
	step: number;

	@Sync('import/content')
	content: ContentImportResource;

	@Sync('import/content@title')
	title: string;

	/* Methods
	============================================*/

	submitForm() {
		// Workaround vue/typescript bug where _form type is 'Vue' rather than 'EaasiForm'
		let form: any = this.$refs._form;
		form.submit();
	}
}

