







































































import Vue from 'vue';
import Component from 'vue-class-component';


@Component({
	name: 'AppFooter',
})
export default class AppFooter extends Vue {
	get copyrightMessage() {
		let now = new Date();
		const thisYear = now.getFullYear();
		return `© EaaSI ${thisYear}`;
	}
};
