






































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Sync } from 'vuex-pathify';
import EaasiForm from '@/components/global/forms/EaasiForm.vue';
import { IRadioOption } from '@/types/Forms';

@Component({
	name: 'EnvironmentImportGeneralMetadata'
})
export default class EnvironmentImportGeneralMetadata extends Vue {

	$refs!: {
		_form: EaasiForm
	};

	/* Computed
	============================================*/

	@Sync('import/environment@title')
	readonly title: string;

	@Sync('import/environment@type')
	readonly environmentType: number;

	/* Data
	============================================*/

	environmentTypes: IRadioOption[] = [
		{
			label: 'Base Environment',
			value: 1,
			description: 'Image of a hard drive with an OS'
		},
		{
			label: 'Content Environment',
			value: 2,
			description: 'Image of a hard drive with an OS & content files'
		}
	]
}

