




































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import HarvesterDetailsModal from './HarvesterDetailsModal.vue';

@Component({
	name: 'HarvesterList',
	components: {
		HarvesterDetailsModal
	}
})
export default class HarvesterList extends Vue {

	/* Props
	============================================*/

	@Prop({type: Array, required: true})
	readonly list: string[];

	/* Data
	============================================*/

	harvesterToEdit: string = null;

	/* Methods
	============================================*/

	syncEndpoint(name: string, full: boolean = false) {
		this.$store.dispatch('admin/syncHarvester', { name, full });
	}
}

