
















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import LabeledItem from '@/components/global/LabeledItem/LabeledItem.vue';
import { ITag } from '@/types/Tag';
import {translatedIcon} from '@/utils/constants';

@Component({
	name: 'ObjectCard',
	components: {
		LabeledItem
	}
})
export default class ObjectCard extends Vue {
	/* Props
	============================================*/
	@Prop({ type: String, required: true })
	readonly title: string;

	@Prop({ type: String, required: true })
	readonly resourceTypeLabel: string;

	@Prop({ type: String, required: true })
	readonly archiveLabel: string;

	/* Computed
	============================================*/

	get objectTags(): ITag[] {
		let tagGroup = [];
		tagGroup.push({
			text: this.resourceTypeLabel,
			icon: translatedIcon('file'),
			color: 'white'
		});
		tagGroup.push({
			text: this.archiveLabel,
			icon: translatedIcon('file'),
			color: 'white'
		});
		return tagGroup;
	}
}
