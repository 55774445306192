










import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

/**
 * A general button for UI interaction
 * @example ../docs/CircleButton.Example.md
 */
@Component({
	name: 'CircleButton'
})
export default class CircleButton extends Vue {

	/* Props
	============================================*/

	/**
	 * Optional color preset
	 */
	@Prop({type: String, required: false, default: 'default'})
	readonly colorPreset: String;

	/**
	 * Font icon name
	 */
	@Prop({type: String, required: true})
	readonly icon: string;

	/**
	 * Adds a size class
	 */
	@Prop({type: String, required: false})
	readonly size: string;
}
