






import Vue from 'vue';
import Component from 'vue-class-component';

/**
 * A loading overlay with an animated spinner
 */
@Component({
	name: 'LoaderOverlay'
})
export default class LoaderOverlay extends Vue {}
