export default {
	AJAX_END: 'AJAX_END',
	AJAX_ERROR: 'AJAX_ERROR',
	AJAX_START: 'AJAX_START',
	TASK_START: 'TASK_START',
	TASK_END: 'TASK_END',
	TASK_ERROR: 'TASK_ERROR',
	REQUEST_LIMIT_REACHED: 'REQUEST_LIMIT_REACHED',
	NOTIFICATION_SHOW: 'NOTIFICATION_SHOW',
	UNAUTHORIZED_ERROR: 'UNAUTHORIZED_ERROR'
};