











import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

/**
 * A Section Heading
 * @example ../docs/SectionHeading.Example.md
 */
@Component({
	name: 'SectionHeading'
})
export default class SectionHeading extends Vue {
	/* Props
    ============================================*/

    // Title of the Section
    @Prop({type: String, required: true})
    readonly title: string

	// Size Modifier
	@Prop({type: String, required: false, validator: (val) => ['small', 'medium', 'large'].indexOf(val) != -1})
	readonly size: string = 'medium'

    // Font icon name (optional)
    @Prop({type: String, required: false})
    readonly icon: string
}
