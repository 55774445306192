































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import SearchFacetWrapper from './SearchFacetWrapper.vue';
import { IResourceSearchFacet } from '@/types/Search.d.ts';

@Component({
	name: 'CheckboxFacet',
	components: {
		SearchFacetWrapper
	}
})
export default class CheckboxFacet extends Vue {

	/* Props
	============================================*/

	@Prop({type: Number, required: false, default: 4})
	readonly maxDisplayLimit: Number

	@Prop({type: Object as () => IResourceSearchFacet, required: true})
	readonly facet: IResourceSearchFacet

	@Prop({ type: Boolean })
	readonly initState: Boolean;

}

