


























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

/**
 * A reusable styled content wrapper
 * @example ../../docs/OptionsBox.Example.md
 */
@Component({
	name: 'OptionsBox',
})
export default class OptionsBox extends Vue {

	/* Props
	============================================*/

	// Optional header text
	@Prop({type: String, required: false})
	readonly header: String;

	// Optional name text
	@Prop({type: String, required: false})
	readonly title: String;

	// Optional icon name
	@Prop({type: String, required: false})
	readonly icon: String;

	hasFooter() {
		return this.$slots.footer;
	}
}

