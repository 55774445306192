





















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
	name: 'ResourceUrlItem',
})
export default class ResourceUrlItem extends Vue {

	/* Props
	============================================*/
	@Prop({type: Object as () => File, required: true})
	readonly fileUrl: string;

	/* Computed
	============================================*/

	selected: boolean = false;

	/* Methods
	============================================*/

	onToggle(isChecked: boolean) {
		this.selected = isChecked;
	}
}

