







































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import { Sync } from 'vuex-pathify';

@Component({
	name: 'SoftwareGeneralInfoForm',
})
export default class SoftwareGeneralInfoForm extends Vue {

	/* Computed
	============================================*/

	@Sync('import/software@title')
	title: string;

	@Sync('import/software@localIdentifier')
	localIdentifier: string;

	@Sync('import/software@localIdentifierSource')
	localIdentifierSource: string;

	@Sync('import/software@version')
	version: string;
}

