


























































import { Component } from 'vue-property-decorator';
import CreateBaseEnvModal from './CreateBaseEnvModal.vue';
import Vue from 'vue';

@Component({
	name: 'BaseEnvironmentWizard',
	components: { CreateBaseEnvModal }
})
export default class BaseEnvironmentWizard extends Vue {

	/**
	 * True if the Choose Template Modal is open
	 */
	isChooseTemplateOpen = false;


	/**
	 * True if a Tips Component is open
	 */
	showTips: Boolean = true;

	searchForEnvironment() {
	}

	openMyResources() {
	}

	/**
	 * Opens the Choose Template Modal
	 */
	chooseTemplate() {
		this.isChooseTemplateOpen = true;
	}

	/**
	 * Closes the Choose Template Modal
	 */
	closeChooseTemplate() {
		this.isChooseTemplateOpen = false;
	}
}
