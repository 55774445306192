























































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { IEaasiResourceSummary, IContent, IContentRequest, ISoftwareObject, IEaasiResource } from '@/types/Resource';
import { resourceTypes } from '@/utils/constants';
import { ILabeledEditableItem } from '@/types/ILabeledItem';
import EditableLabeledItemList from '../shared/EditableLabeledItemList.vue';
import ResourceDetailsSummary from '../shared/ResourceDetailsSummary.vue';
import ModeToggle from '../shared/ModeToggle.vue';
import RenderingEnvironments from '../software/RenderingEnvironments.vue';
import SlideMenuControlButtons from '@/components/resources/SlideMenuControlButtons.vue';
import ResourceSlideMenu from '@/components/resources/ResourceSlideMenu.vue';
import { ROUTES } from '@/router/routes.const';
import { IEaasiTab } from 'eaasi-nav';
import { Sync } from 'vuex-pathify';

@Component({
	name: 'ContentDetailsScreen',
	components: {
        EditableLabeledItemList,
        ResourceDetailsSummary,
		ModeToggle,
		SlideMenuControlButtons,
		ResourceSlideMenu,
		RenderingEnvironments
	}
})
export default class ContentDetailsScreen extends Vue {

    /* Data
	============================================*/
	activeContent: IContent = null;
	mods = ['Review Mode', 'Edit Mode'];
	activeMode: string = this.mods[0];
	objectDetailsItems: ILabeledEditableItem[] = [];
	renderingEnvs: [] = [];
	confirmModal: boolean = false;
	errorMessage: string = null;

	// Slide menu
	actionMenuTabs: IEaasiTab[] = [
		{
			label: 'Actions Menu'
		}
	];

	actionMenuActiveTab: IEaasiTab = null;

	/* Computed
	============================================*/
	@Sync('resource/selectedResources')
	resources: IEaasiResource[];

	get isEditMode(): boolean {
		return this.activeMode === 'Edit Mode';
	}

	get resourceSummary(): IEaasiResourceSummary {
		return {
			id: this.activeContent.metadata.id,
			title: this.activeContent.metadata.title,
			description: null,
			content: null,
			subContent: null,
			tagGroup: [],
			resourceType: resourceTypes.CONTENT,
			isPublic: false
		};
	}

	get isActionMenuOpen(): boolean {
		return this.actionMenuActiveTab != null;
	}

	get actionMenuStyles(): string {
		let styles = '';
		if (!this.isActionMenuOpen) return styles;
		let maxWidth = document.body.clientWidth - (430 + 90); // screen width - (action menu width + side menu bar width)
		styles += `overflow-y: scroll; max-width: ${maxWidth}px;`;
		return styles;
	}

	get innerStyles(): string {
		let styles = '';
		if (!this.isActionMenuOpen) return styles;
		let width = '95vw'; // screen width
		styles += `width: ${width};`;
		return styles;
	}

	get resourceTitle(): string {
		return this.activeContent && this.activeContent.metadata && this.activeContent.metadata.title
			? `${this.activeContent.metadata.title}` : 'Content Details';
	}

    /* Methods
	============================================*/
	onModeChange(mode: string) {
		this.activeMode = mode;
	}

	async saveDetails() {
		const result = await this.$store.dispatch('software/saveContent', this.activeContent);
		if (result && result.status === '0') this.activeMode = this.mods[0];
	}

	async moveToSoftware() {
		this.errorMessage = null;
		const softwareObject: ISoftwareObject = {
			allowedInstances: -1,
			archiveId: 'zero conf',
			exportFMTs: [],
			importFMTs: [],
			isOperatingSystem: false,
			label: this.activeContent.metadata.title,
			licenseInformation: '',
			nativeFMTs: [],
			objectId: this.activeContent.metadata.id,
			isPublic: false
		};
		const result = await this.$store.dispatch('software/saveSoftwareObject', softwareObject);
		if (!result || result.status !== '0') {
			this.errorMessage = 'Having troubles saving this object as a software.';
			return;
		}
		this.confirmModal = false;
		this.$router.replace(`/resources/software?resourceId=${softwareObject.objectId}&archiveId=${softwareObject.archiveId}`);
	}

	async refresh() {
		this.activeMode = 'Review Mode';
		await this.init();
	}

	async init() {
		const contentId = this.$route.query.resourceId as string;
		const archiveName = this.$route.query.archiveId as string;
		const contentRequest: IContentRequest = { contentId, archiveName };
		this.activeContent = await this.$store.dispatch('software/getContent', contentRequest);
		await this.$store.commit('resource/SET_RESOURCE_NAME', this.activeContent.metadata.title);
		const contentResource: IEaasiResource = {
			id: this.activeContent.metadata.id,
			title: this.activeContent.metadata.title,
			archiveId: archiveName,
			resourceType: resourceTypes.CONTENT,
			isPublic: false
		};
		this.resources = [{...contentResource, resourceType: resourceTypes.CONTENT }];
		if (!this.activeContent) return;
		this.activeMode = this.mods[0];
		this._populateObjectDetails();
	}

	openActionMenu(tab: IEaasiTab = this.actionMenuTabs[1]) {
		this.actionMenuActiveTab = tab;
	}

	closeActionMenu() {
		this.actionMenuActiveTab = null;
	}

    /* Lifecycle Hooks
	============================================*/
    created() {
		this.init();
	}

	beforeDestroy() {
		this.resources = [];
	}

	/* Helpers
	============================================*/
	_populateObjectDetails() {
		this.objectDetailsItems = [
			{
				label: 'Object ID',
				value: this.activeContent.metadata.id,
				property: 'id',
				readonly: true,
				editType: 'text-input',
				changed: false
			},
			{
				label: 'Object Label',
				value: this.activeContent.metadata.title,
				property: 'label',
				readonly: true,
				editType: 'text-input',
				changed: false
			}
		];
	};

	goBackToResults() {
		this.$router.push(`${ROUTES.RESOURCES.EXPLORE}?retrieveQuery=true`);
	}
}

