


















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {translatedIcon} from '@/utils/constants';

/**
 * A drag-and-drop file upload area
 * @example ../docs/FileDropZone.Example.md
 */
@Component({
	name: 'FileDropzone',
})
export default class FileDropzone extends Vue {

	/* Props
	============================================*/

	/**
	 * The max number of files that can be added at a time
	 */
	@Prop({type: Number, required: false})
	readonly limit: number;

	/* Computed
	============================================*/

	get label() {
		return 'Drag File Here To Upload';
	}

	get uploadIcon() {
		return translatedIcon('cloud-upload');
	}

	/* Data
	============================================*/

	/**
	 * Whether or not a file is being dragged over the area
	 */
	dragover: boolean = false;

	/**
	 * A list of files that have been dropped in the uploader
	 */
	files: File[] = [];

	/* Methods
	============================================*/

	/**
	 * Called when a file is dropped in the dropzone
	 */
	addFile(e: DragEvent) {
		let fileList = e.dataTransfer.files as FileList;
		if(!fileList || !fileList.length) return;
		for(let i=0; i<fileList.length; i++) {
			if( this.limit && (i+1) > this.limit) continue;
			this.files.push(fileList[i]);
		}
		this.$emit('change', this.files);
	}

}

