













































































import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import BaseEnvironmentWizard from './base-environment/BaseEnvironmentWizard.vue';
import SoftwareResourcesWizard from './SoftwareResourcesWizard.vue';
import ContentResourcesWizard from './ContentResourcesWizard.vue';
import InfoMessage from './shared/InfoMessage.vue';
import { ROUTES } from '@/router/routes.const';
import CreateBaseEnvModal from './base-environment/CreateBaseEnvModal.vue';
import { ICreateEnvironmentPayload, ICreateEnvironmentResponse } from '@/types/Import';
import { Sync } from 'vuex-pathify';
import { generateNotificationError } from '@/helpers/NotificationHelper';
import eventBus from '@/utils/event-bus';
import { IEnvironment } from '@/types/Resource';
import EmulationProjectEnvironment from '@/models/emulation-project/EmulationProjectEnvironment';
import { IUserImportRelationRequest, IUserImportedResource } from '@/types/UserImportRelation';
import { resourceTypes } from '@/utils/constants';

@Component({
	name: 'EmulationProjectOptions',
	components : {
		BaseEnvironmentWizard,
		SoftwareResourcesWizard,
		InfoMessage,
		CreateBaseEnvModal,
		ContentResourcesWizard
	}
})
export default class EmulationProjectOptions extends Vue {

	@Sync('emulationProject/createEnvironmentPayload')
	createEnvironmentPayload: ICreateEnvironmentPayload;

	@Sync('emulationProject/environment')
	environment: EmulationProjectEnvironment;

	createBaseEnvModal: boolean = false;

	search() {
		this.$router.push(ROUTES.RESOURCES.EXPLORE);
	}

	myResources() {
		this.$router.push(ROUTES.RESOURCES.MY_RESOURCES);
	}

	createBaseEnvironment() {
		this.createEnvironmentPayload = {
			nativeConfig: '',
			templateId: '',
			driveSettings: [],
			operatingSystemId: '',
			label: ''
		};
		this.createBaseEnvModal = true;
	}

	async saveBaseEnvironment() {
		const response: ICreateEnvironmentResponse = await this.$store.dispatch('import/createEnvironment', this.createEnvironmentPayload);
		if (!response || !response.id) {
			eventBus.$emit('notification:show', generateNotificationError(`Having troubles creating ${this.createEnvironmentPayload.label} environment, please try again.`));
			return;
		}
		let userImportRelationRequest: IUserImportRelationRequest = {
			resourceType: resourceTypes.ENVIRONMENT,
			resourceId: response.id,
		};
		let importResponse: IUserImportedResource = await this.$store.dispatch('import/createUserImportRelation', userImportRelationRequest);
		if (!importResponse || !importResponse.id) {
			await this.$store.dispatch('resource/deleteEnvironment', response.id);
			eventBus.$emit('notification:show', generateNotificationError(`Having troubles saving ${this.createEnvironmentPayload.label} environment to My Resources, please try again.`));
			return;
		}
		const baseEnv: IEnvironment = await this.$store.dispatch('resource/getEnvironment', response.id);
		await this.$store.dispatch('emulationProject/addResources', [baseEnv]);
		this.environment = new EmulationProjectEnvironment(baseEnv);
		this.createBaseEnvModal = false;
		// mutate base env at this point
		await this.$router.push(ROUTES.EMULATION_PROJECT.DETAILS);
	}

}

