

















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IDrive, IEditableDrive, IResourceDrive, IEaasiResource } from '@/types/Resource';
import DriveEditModal from './DriveEditModal.vue';
import { generateId, jsonCopy } from '@/utils/functions';
import DriveCard from './DriveCard.vue';
import EditableDrive from '@/models/emulation-project/EditableDrive';
import { Sync, Get } from 'vuex-pathify';

@Component({
	name: 'DriveSettings',
	components: {
		DriveEditModal,
		DriveCard
	}
})
export default class DriveSettings extends Vue {

    /* Props
    ============================================*/
    @Prop({ type: Boolean })
	readonly: Boolean;
	
	@Prop({ type: Array as () => IDrive[], required: true })
	drives: IDrive[]

    /* Computed
    ============================================*/
	get drivesWithIds(): IEditableDrive[] {
		return this.drives.map(d => {
			return {...d, uid: generateId()};
		});
	}

    /* Data
    ============================================*/
	activeDrive: IEditableDrive = null;

    /* Methods
    ============================================*/
    generateDrive(type: string = 'floppy', boot = false) {
		return new EditableDrive();
	}

	save(drive: IEditableDrive) {
		let updatedDrives: IEditableDrive[] = this.drivesWithIds;
		this.drivesWithIds.some(d => d.uid === drive.uid)
			? updatedDrives = this.drivesWithIds.map(d => d.uid === drive.uid ? drive : d)
			: updatedDrives.push(drive);

		this.$emit('update-drives', updatedDrives);
		this.closeModal();
	}

	closeModal() {
		this.activeDrive = null;
	}

	add() {
		this.activeDrive = this.generateDrive('');
	}

	edit(drive: IEditableDrive) {
		this.activeDrive = jsonCopy(drive);
	}

    remove(drive: IEditableDrive) {
		const updatedDrives = this.drivesWithIds.filter(d => d.uid != drive.uid);
		this.$emit('update-drives', updatedDrives);
    }

}
