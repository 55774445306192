










































import AdminScreen from '../AdminScreen.vue';
import { Component } from 'vue-property-decorator';
import HarvesterList from './HarvesterList.vue';
import HarvesterDetailsModal from './HarvesterDetailsModal.vue';
import { Get } from 'vuex-pathify';
import config from '@/config/index';

@Component({
	name: 'EmulatorManagement',
	components: {
		HarvesterList,
		HarvesterDetailsModal
	}
})
export default class EmulatorManagement extends AdminScreen {

	/* Computed
	============================================*/

	@Get('admin/harvesters')
	list: string[]

	@Get('admin/apiKey')
	readonly apiKey: string;

	get result() {
		if(!this.list || !this.list.length) return [];
		let q = this.keyword.toLowerCase();
		return this.list.filter(x => x.toLowerCase().indexOf(q) > -1);
	}

	/* Data
	============================================*/

	keyword: string = '';
	showAddModal: boolean = false;
	readonly providerUrl: string = `${config.BASE_URL}/oaipmh/providers`;


	/* Methods
	============================================*/

	addHarvester() {
		this.showAddModal = true;
	}

	search(keyword) {
		this.keyword = keyword;
	}

	/* Lifecycle Hooks
	============================================*/

	mounted() {
		this.$store.dispatch('admin/getHarvesters');
		this.$store.dispatch('admin/getApiKey');
	}
}
