



























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

/**
 * A simple pop-up modal with three content slots: content (default), header, and footer
 */
@Component({
	name: 'Modal'
})
export default class Modal extends Vue {

	/* Props
    ============================================*/

    /**
     * Alternative size of the modal. Accepts 'sm, small, lg, or large'
     */
    @Prop({type: String, default: 'medium'})
	readonly size: string

	/**
     * Modal header headline
     */
    @Prop({type: String, required: false})
    readonly title: string

	/**
	 * Show "X" in the top right-hand corner of the modal to cloase
	 */
	@Prop({type: Boolean, required: false, default: false})
	readonly showX: string


	/* Lifecycle Hooks
	============================================*/

    mounted() {
		document.body.classList.add('modal-open');
    }

    beforeDestroy() {
    	document.body.classList.remove('modal-open');
    }

}

