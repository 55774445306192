






















































































import {archiveTypes} from '@/utils/constants';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import {IContentRequest, IEnvironment, IObjectClassificationRequest} from '@/types/Resource';
import {Get, Sync} from 'vuex-pathify';
import EnvironmentPickerModal from './EnvironmentPickerModal.vue';
import ResourceSearchQuery from '@/models/search/ResourceSearchQuery';
import { ITaskState } from '@/types/Task';
import { ROUTES } from '../../../../router/routes.const';

@Component({
    name: 'RenderingEnvironments',
    components: {
        EnvironmentPickerModal
    }
})
export default class RenderingEnvironments extends Vue {

	@Prop({ type: String, required: true })
	resourceId: string;

	@Prop({ type: String, required: true })
	archiveId: string;

	/* Computed
	============================================*/
	@Sync('import/constructedTitle')
	constructedTitle: string;

	@Get('resource/activeEnvironment@title')
	activeEnvironmentName: string;

	@Get('task/completedTasks')
	completedTasks: ITaskState[];

	get isSoftware() {
		return this.$route.path.indexOf('software') > 0;
	}

    /* Data
    ============================================*/
    environments: IEnvironment[] = [];
    showEnvPicker: boolean = false;
	loading: boolean = false;
	confirmAction: string = null;
	renderingEnvs: any[] = [];
	classifyTask: ITaskState = null;

    /* Methods
    ============================================*/
    async init() {
        let query = new ResourceSearchQuery();

        this.$store.commit('resource/SET_QUERY', {
			...query,
			types: ['Environment'],
			archives: [archiveTypes.REMOTE, archiveTypes.PUBLIC, archiveTypes.DEFAULT],
			limit: 1000
		});

        const { environments } = await this.$store.dispatch('resource/searchResources');
        if (!environments) return;

		this.environments = environments.result.filter(env => [archiveTypes.PUBLIC, archiveTypes.DEFAULT]
			.includes(env.archive));
		await this.$store.dispatch('resource/clearSearchQuery');
		this.$store.commit('resource/SET_RESULT', null);
    }

    addEnv(envId: string) {
		const env = this.findEnv(envId);
		this.renderingEnvs.push({
			id: env.envId,
			label: env.title,
			objectEnvironment: false
		});
        this.showEnvPicker = false;
    }

    findEnv(envId: string): IEnvironment {
        return this.environments.find(env => env.envId === envId);
    }

    async classify() {
		this.confirmAction = null;
        this.loading = true;
        const classifyRequest: IObjectClassificationRequest = {
            archiveId: this.archiveId,
			objectId: this.resourceId,
            updateClassification: false,
            updateProposal: true,
        };
		let task: ITaskState = await this.$store.dispatch('software/classify', classifyRequest);
		task.description = `Detect Environments for ${this.isSoftware ? 'software' : 'content'} ${this.resourceId}`;
		await this.$store.dispatch('task/addTaskToQueue', task);
		this.classifyTask = task;
    }

    async run(env) {
		const idType = this.isSoftware ? 'softwareId' : 'objectId';

		let softwareId = null;
		let objectId = null;

		if (this.isSoftware) {
			softwareId = this.resourceId;
		} else {
			objectId = this.resourceId;
		}

		let payload = {
			envType: this.isSoftware ? 'softwareEnvironment' : 'objectEnvironment',
			softwareId: softwareId,
			objectId: objectId
		};

		await this.$store.dispatch('import/setEnvironmentType', payload);

		this.$store.commit('import/SET_IS_CONSTRUCTED_ENVIRONMENT', true);

        await this.$router.push(`${ROUTES.ACCESS_INTERFACE}/${env.id}?${idType}=${this.resourceId}&archiveId=${this.archiveId}`);
    }

    remove(env) {
        this.renderingEnvs = this.renderingEnvs.filter(i => i.id !== env.id);
    }

    /* Lifecycle Hooks
    ============================================*/
    async beforeMount() {
        await this.init();
    }

	/* Watcher
	============================================*/
	@Watch('completedTasks')
	onActivePollingTask(completedTasks: ITaskState[]) {
		if (completedTasks.length < 1 || !this.classifyTask) return;
		const currentTask = completedTasks.find(task => task.taskId === this.classifyTask.taskId);
		if (!currentTask) return;
		const res = JSON.parse(currentTask.object);
		this.renderingEnvs = res.environmentList;
		this.loading = false;
	}

}
