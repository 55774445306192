

























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Modal from '@/components/global/Modal/Modal.vue';
import UiButton from '@/components/global/UiButton.vue';

@Component({
	name: 'ConfirmModal',
	components: {
		Modal,
		UiButton
	}
})
export default class ConfirmModal extends Vue {

	/* Props
	============================================*/

	/**
     * Confirm button text
     */
    @Prop({type: String, required: false, default: 'Cancel'})
	readonly cancelLabel: string;

    /**
     * Confirm button text
     */
    @Prop({type: String, required: false, default: 'Confirm'})
	readonly confirmLabel: string;

    /**
     * The user-facing title at the top of the modal
     */
    @Prop({type: String, required: true})
    readonly title: string;

	 /**
     * Alternative size of the modal. Accepts 'sm, small, lg, or large'
     */
    @Prop({type: String, default: 'small'})
	readonly size: string;

    /* Methods
	============================================*/

    cancel() {
    	this.$emit('close');
    	this.$emit('click:cancel');
    }

}

