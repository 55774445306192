




















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ILabeledEditableItem } from '@/types/ILabeledItem';
import EditableTextItem from '../shared/EditableTextItem.vue';
import EditableCheckboxItem from '../shared/EditableCheckboxItem.vue';

@Component({
    name: 'ConfigureNetwork',
    components: {
        EditableTextItem,
        EditableCheckboxItem
    }
})
export default class ConfigureNetwork extends Vue {

    /* Props
    ============================================*/
    @Prop({ type: Array })
    networkItems: ILabeledEditableItem[];

    @Prop({ type: Boolean })
    readonly: Boolean;

    /* Computed
    ============================================*/
    get connectEnvs() {
        return this.networkItems.find(i => i.property === 'connectEnvs');
    }
    get enableInternet() {
        return this.networkItems.find(i => i.property === 'enableInternet');
    }
    get serverMode() {
        return this.networkItems.find(i => i.property === 'serverMode');
    }
    get enableSocks() {
        return this.networkItems.find(i => i.property === 'enableSocks');
    }
    get localServerMode() {
        return this.networkItems.find(i => i.property === 'localServerMode');
    }
    get serverIp() {
        return this.networkItems.find(i => i.property === 'serverIp');
    }
    get serverPort() {
        return this.networkItems.find(i => i.property === 'serverPort');
    }
    get helpText() {
        return this.networkItems.find(i => i.property === 'helpText');
    }

}
