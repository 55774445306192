








































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { EaasiForm } from '@/components/global';
import { generateNotificationSuccess } from '@/helpers/NotificationHelper';
import eventBus from '@/utils/event-bus';
import { IKeyboardLanguage, IKeyboardLayout, IKeyboardSettings } from 'eaasi-admin';

const kbLayouts = require('../../../assets/json/kbLayouts.json');

@Component({
	name: 'NodePreferencesModal'
})
export default class NodePreferencesModal extends Vue {

	$refs!: {
		_form: EaasiForm
	};

	/* Props
	============================================*/

	/* Computed
	============================================*/
	get keyboardLanguages(): IKeyboardLanguage[] {
		return this.kbLayouts.languages;
	}

	get keyboardLayouts(): IKeyboardLayout[] {
		return this.kbLayouts.layouts;
	}

	get disabled(): boolean {
		return !this.selectedKeyboardLanguage || !this.selectedKeyboardLayout;
	}

	/* Data
	============================================*/
	readonly kbLayouts = kbLayouts;
	selectedKeyboardLanguage: string = null;
	selectedKeyboardLayout: string = null;

	/* Methods
	============================================*/
	cancel() {
		this.selectedKeyboardLanguage = null;
		this.selectedKeyboardLayout = null;
		this.$emit('close');
	}

	async saveKeyboardSettings() {
		const language = this.keyboardLanguages.find(language => language.name === this.selectedKeyboardLanguage);
		const layout = this.keyboardLayouts.find(layout => layout.name === this.selectedKeyboardLayout);
		const payload: IKeyboardSettings = {language, layout};
		await this.$store.dispatch('admin/setKeyboardSettings', payload);
		const notification = generateNotificationSuccess('Keyboard Settings saved successfully.');
		eventBus.$emit('notification:show', notification);
	}

	async init() {
		const keyboardSettings: IKeyboardSettings = await this.$store.dispatch('admin/getKeyboardSettings');
		if (keyboardSettings && keyboardSettings.language && keyboardSettings.layout) {
			this.selectedKeyboardLanguage = keyboardSettings.language.name;
			this.selectedKeyboardLayout = keyboardSettings.layout.name;
		}
	}

	/* Lifecycle Hooks
	============================================*/

	beforeMount() {
		this.init();
	}
}

